import styled from 'styled-components';

import { ReactComponent as JumboIcon } from 'assets/images/jumbo-token-logo.svg';
import Buttons from 'shared/components/Buttons';
import SpecialContainer from 'shared/components/SpecialContainer';

const Container = styled(SpecialContainer)`
  align-self: center;
  width: 100%;
  margin-bottom: 2rem;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    margin-right: .5rem;
  }
`;

const Description = styled.div`
  color: ${({ theme }) => theme.grey};
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.375rem;
  & > span {
    color: ${({ theme }) => theme.white};
  }
`;

const Button = styled(Buttons.Primary)`
  width: 100%;
  margin-top: 1rem;
`;

export default {
  Container,
  Title,
  JumboIcon,
  Description,
  Button,
};

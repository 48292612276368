import pageStyles from 'pages/styles';
import { useData } from 'providers/DataProvider';
import { getToken } from 'providers/helpers';
import { StakingProvider } from 'providers/LocalProviders/StakingProvider';
import Token from 'shared/components/Token';
import { sortingStaking } from 'shared/utils/sorting';

import CreateStaking from './components/CreateStaking';
import StakingCard from './components/StakingCard';
import StickyContainer from './components/StickyContainer';
import styles from './styles';

export default function StakingPage() {
  const { tokens, staking } = useData();
  const rewardToken = getToken(staking.rewardTokenId, tokens);
  const metadata = rewardToken?.metadata;
  if (!rewardToken || !metadata) return null;
  const sortedStakingFarms = sortingStaking(staking.farms.arr);
  return (
    <StakingProvider token={rewardToken}>
      <pageStyles.Container>
        <Token token={rewardToken} />
        <styles.Wrapper>
          <styles.DynamicContainer>
            <styles.StakingList>
              {sortedStakingFarms.map((stakingElement) => (
                <StakingCard key={stakingElement.stakeId} staking={stakingElement} token={rewardToken} />
              ))}
            </styles.StakingList>
            <CreateStaking />
          </styles.DynamicContainer>
          <StickyContainer />
        </styles.Wrapper>
      </pageStyles.Container>
    </StakingProvider>
  );
}

import Big from 'big.js';
import { utils } from 'near-api-js';

import { DEFAULT_GAS, SECONDS_IN_MILLISECONDS, ZERO } from 'shared/constant';

export * from './formatAmount';
export * from './stringOperations';
export * from './initialFormValues';
export * from './validationSchema';
export * from './dateOperation';
export { default as getFarmStatus } from './getFarmStatus';
export { default as formatPool } from './formatPool';
export { default as formatFarm } from './formatFarm';
export { default as formatValues } from './formatValues';
export { default as getSeedsAmount } from './getSeedsAmount';
export { default as calcRewardPerSession } from './calcRewardPerSession';

export const secondsToMilliseconds = (num: number) => num * SECONDS_IN_MILLISECONDS;
export const millisecondsToSeconds = (num: number) => num / SECONDS_IN_MILLISECONDS;

export function isNotNullOrUndefined<T extends Object>(input: null | undefined | T): input is T {
  return input != null;
}

export function onlyUniqueValues(values: string[]) {
  return Array.from(new Set(values));
}

export const toArray = (map: { [key: string]: any }) => Object.values(map);
export const toMap = (
  array: any[],
  getBy: string,
) => array.reduce((acc, item) => ({ ...acc, [item[getBy]]: item }), {});

export const getGas = (gas?: string): string => (gas
  ? new Big(gas).toString()
  : new Big(DEFAULT_GAS).toString()
);

export const getAmount = (amount?: string): string => (amount
  ? new Big(utils.format.parseNearAmount(amount) ?? ZERO).toFixed()
  : new Big(ZERO).toFixed()
);

import styled from 'styled-components';

import { ReactComponent as HapiLogo } from 'assets/images/hapi-logo.svg';

const Container = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  color: ${({ theme }) => theme.white};
  padding: 1.5rem 4.5rem 2.563rem 4.5rem;
  transition: all 1s ease;
`;

const SocialNetworkContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0 1 0;
  border-radius: 32px;
`;

const ChildSocialNetwork = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.938rem 1.188rem;
  text-decoration: none;
  & > svg {
    fill: ${({ theme }) => theme.grey}
  }
  & > div {
    margin-top: .5rem;
    font-style: normal;
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 1.063rem;
    text-align: center;
    color: ${({ theme }) => theme.grey};
  }
  :hover {
    & > svg {
      fill: ${({ theme }) => theme.white}
    }
    & > div {
      color: ${({ theme }) => theme.white};
    }
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex: 1 1 0;
  justify-content: flex-end;
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.031rem;
  color: ${({ theme }) => theme.grey};
`;

const LeftContainer = styled.div`
  flex: 1 1 0;
`;

const HapiButton = styled.a`
  min-height: 2.25rem;
  max-width: 115px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.yellowHapi};
  border-radius: 8px;
  color: ${({ theme }) => theme.yellowHapi};
  text-decoration:none;
  margin-top: 0;
  p, span {
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: .625rem;
    line-height: 0.688rem;
  }
  :hover {
    & span {
      text-decoration: underline;
    }
  }
  
  :visited, :hover {
    color: ${({ theme }) => theme.yellowHapi};
  }
`;

const HapiLogoContainer = styled.div`
  background-color: ${({ theme }) => theme.yellowHapi};
  border-radius: 6px 0 0 6px;
`;

const HapiTitle = styled.p`
  color: ${({ theme }) => theme.yellowHapi};
  margin-block-start: 0;
  margin-block-end: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.375rem 0.313rem;
  white-space: nowrap;
`;

export default {
  Container,
  SocialNetworkContainer,
  ChildSocialNetwork,
  TextContainer,
  LeftContainer,
  HapiButton,
  HapiLogoContainer,
  HapiTitle,
  HapiLogo,
};

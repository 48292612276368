import { FieldInputProps, FieldMetaProps } from 'formik';

import { ITranslationKeys } from 'services/translation';
import { EFromValues, ETypeInput } from 'shared/interfaces';
import { formTitles, formPlaceholders, formTooltip } from 'shared/utils';

import Tooltip from '../Tooltip';
import MaterialDateTimePicker from './DateTimePicker';
import styles from './styles';

interface IInput {
  field: FieldInputProps<any>,
  meta: FieldMetaProps<any>,
  name: EFromValues,
  type: ETypeInput,
  setFieldValue?: (field: string, value: string, shouldValidate?: boolean | undefined) => void,
}
export default function Input({
  field,
  meta,
  name,
  type,
  setFieldValue,
}: IInput) {
  const error = Boolean(meta.touched && meta.error);
  return (
    <styles.Container>
      <styles.Title>
        {formTitles[name]}
        <Tooltip title={formTooltip[name] as ITranslationKeys} />
      </styles.Title>
      {type === ETypeInput.DATE_TIME
        ? (
          <MaterialDateTimePicker
            setFieldValue={setFieldValue}
            value={field.value}
            name={name}
            error={error}
          />
        )
        : (
          <styles.InputWrapper error={error}>
            <styles.StyledInput
              {...field}
              placeholder={formPlaceholders[name]}
              autoComplete="off"
              onWheel={(e: any) => e.target!.blur()}
            />
          </styles.InputWrapper>
        )}
      {
        error && (
        <styles.Error>
          {meta.touched && meta.error}
        </styles.Error>
        )
      }
    </styles.Container>
  );
}

export enum StakingChangeMethods {
  createSimpleFarm = 'create_simple_farm',
}

export const StakingContractViewMethod = {
  listFarmsBySeed: 'list_farms_by_seed',
  getSeedInfo: 'get_seed_info',
  listUserSeeds: 'list_user_seeds',
  getUnclaimedReward: 'get_unclaimed_reward',
  getUserRps: 'get_user_rps',
  storageBalanceOf: 'storage_balance_of',
  getReward: 'get_reward',
};

export enum ESeedType {
  FT = 'FT',
  MFT = 'MFT',
}

export interface SeedInfo {
  seed_id: string,
  seed_type: ESeedType,
  farms: Array<string>,
  next_index: number,
  amount: string,
  min_deposit: string,
}

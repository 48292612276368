import { useField } from 'formik';
import { MutableRefObject, useRef, useState } from 'react';

import { ITranslationKeys } from 'services/translation';
import useOnClickOutside from 'shared/hooks/useOnClickOutside';
import { EFromValues, ETypeInput } from 'shared/interfaces';
import { formPlaceholders, formTitles, formTooltip } from 'shared/utils';

import Tooltip from '../Tooltip';
import styles from './styles';

interface IAutocompleteInput {
  value: string,
  name: EFromValues,
  type: ETypeInput,
  optionList: Array<{ title: string; value: string | number; }>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
}

export default function AutocompleteInput({
  value,
  name,
  type,
  optionList,
  setFieldValue,
}: IAutocompleteInput) {
  const props = {
    value,
    label: formTitles[name],
    name,
    type,
    placeholder: formPlaceholders[name],
    optionList,
    list: optionList ? name : undefined,
  };
  const [field, meta] = useField(props);
  const error = Boolean(meta.touched && meta.error);
  const node = useRef<HTMLDivElement>();
  const [focused, setFocused] = useState(false);
  const handleClick = () => setFocused(!focused);

  useOnClickOutside(node, focused ? handleClick : undefined);
  return (
    <styles.Container ref={node as MutableRefObject<HTMLDivElement>}>
      <styles.Title>
        {formTitles[name]}
        <Tooltip title={formTooltip[name] as ITranslationKeys} />
      </styles.Title>
      <styles.InputWrapper error={!!error}>
        <styles.StyledInput
          {...field}
          {...props}
          onClick={handleClick}
          autoComplete="off"
          onWheel={(e: any) => e.target!.blur()}
          onChange={(e) => {
            setFieldValue(name, e.target.value.trim());
          }}
        />
        {focused && optionList.length !== 0 && (
        <styles.Datalist>
          {props.optionList.map((el) => (
            <styles.Option
              key={el.title + el.value}
              onClick={() => {
                setFieldValue(name, el.value);
                handleClick();
              }}
            >
              <span>{el.value}</span>
              <span>-</span>
              {el.title}
            </styles.Option>
          ))}
        </styles.Datalist>
        )}
      </styles.InputWrapper>
      {
        error && (
        <styles.Error>
          {meta.touched && meta.error}
        </styles.Error>
        )
      }
    </styles.Container>
  );
}

import { useFormikContext } from 'formik';
import { useMemo } from 'react';

import { useData } from 'providers/DataProvider';
import Input from 'shared/components/Input';
import Select from 'shared/components/Input/Select';
import Translate from 'shared/components/Translate';
import {
  ETypeInput, IFormValues, EFromValues,
} from 'shared/interfaces';
import { getPoolListForSelect } from 'shared/utils/getListForSelect';

import styles from './styles';

export default function TermsForm() {
  const {
    values, setFieldValue, getFieldMeta, getFieldProps,
  } = useFormikContext<IFormValues>();
  const { pools, prices } = useData();
  const poolList = useMemo(() => getPoolListForSelect(pools, prices), [pools, prices]);
  return (
    <styles.Container>
      <styles.Title>
        <styles.JumboIcon />
        <Translate value="title.terms" />
      </styles.Title>
      <Select
        name={EFromValues.poolId}
        value={values.poolId}
        type={ETypeInput.TEXT}
        optionList={poolList}
        setFieldValue={setFieldValue}
      />
      <Input
        type={ETypeInput.NUMERICAL}
        name={EFromValues.sessionInterval}
        field={getFieldProps(EFromValues.sessionInterval)}
        meta={getFieldMeta(EFromValues.sessionInterval)}
      />
      <Input
        type={ETypeInput.NUMERICAL}
        name={EFromValues.distributionAmount}
        field={getFieldProps(EFromValues.distributionAmount)}
        meta={getFieldMeta(EFromValues.distributionAmount)}
      />
      <Input
        type={ETypeInput.NUMERICAL}
        name={EFromValues.distributionInDay}
        field={getFieldProps(EFromValues.distributionInDay)}
        meta={getFieldMeta(EFromValues.distributionInDay)}
      />
      <Input
        type={ETypeInput.DATE_TIME}
        name={EFromValues.startAt}
        field={getFieldProps(EFromValues.startAt)}
        meta={getFieldMeta(EFromValues.startAt)}
        setFieldValue={setFieldValue}
      />
      <Input
        type={ETypeInput.NUMERICAL}
        name={EFromValues.minDeposit}
        field={getFieldProps(EFromValues.minDeposit)}
        meta={getFieldMeta(EFromValues.minDeposit)}
      />
      <styles.Description>
        <span>
          <Translate value="description.note" />
        </span>
        <br />
        <Translate value="description.termsForm1" />
        <br />
        <Translate value="description.termsForm2" />
      </styles.Description>
    </styles.Container>
  );
}

import Big from 'big.js';
import dayjs from 'dayjs';

import { IFormattedStaking } from 'providers/interfaces';
import { stakingSeedId } from 'services/config';
import { EFarmStatus, IFarmInfo, ITokenMetadata } from 'services/interfaces';
import {
  DAYS_A_YEAR, ONE_HUNDRED, SECONDS_IN_A_DAY, ZERO,
} from 'shared/constant';
import { IFormStakingValues, IFormattedStakingValues } from 'shared/interfaces';
import {
  calcRewardPerSession, isEmptyStr, millisecondsToSeconds, parseTokenAmount, toMap,
} from 'shared/utils';

import getFarmStatus from './getFarmStatus';

export const formatStaking = (staking: IFarmInfo, totalStaked: string): IFormattedStaking => {
  const id = staking.farm_id.slice(staking.farm_id.indexOf('#') + 1);
  const rewardPerSeconds = Big(staking.reward_per_session).div(staking.session_interval).toFixed();
  const stakingDuration = Big(staking.total_reward).div(rewardPerSeconds).toNumber();
  const endAt = staking.start_at + stakingDuration;
  const statusFarm = getFarmStatus(staking.farm_status, staking.start_at, endAt);
  let apy = ZERO;
  if (Big(totalStaked).gt(ZERO) && (statusFarm === EFarmStatus.Running || statusFarm === EFarmStatus.Created)) {
    const rewardForYear = Big(rewardPerSeconds).times(SECONDS_IN_A_DAY).times(DAYS_A_YEAR).toFixed();
    apy = Big(rewardForYear).div(totalStaked).times(ONE_HUNDRED).toFixed(2);
  }
  return {
    id: Number(id),
    stakeId: staking.farm_id,
    type: staking.farm_kind,
    status: statusFarm,
    seedId: staking.seed_id,
    rewardTokenId: staking.reward_token,
    startAt: staking.start_at,
    endAt,
    rewardPerSession: staking.reward_per_session,
    sessionInterval: staking.session_interval,
    totalReward: staking.total_reward,
    currentRound: staking.cur_round,
    lastRound: staking.last_round,
    claimedReward: staking.claimed_reward,
    unclaimedReward: staking.unclaimed_reward,
    beneficiaryReward: staking.beneficiary_reward,
    rewardPerSeconds,
    apy,
  };
};

export const formatStakingValues = (
  values: IFormStakingValues,
  metadata: ITokenMetadata,
  rewardToken: string,
): IFormattedStakingValues => {
  const {
    minDeposit,
    sessionInterval,
    startAt,
    distributionInDay,
    distributionAmount,
  } = values;
  const { decimals } = metadata;

  const { rewardPerSession } = calcRewardPerSession(distributionInDay, distributionAmount, sessionInterval);
  const parsedRewardPerSession = parseTokenAmount(rewardPerSession, decimals);

  const startAtValueOf = dayjs(startAt)
    .add(dayjs().utcOffset(), 'minutes').valueOf();

  const formattedValues: IFormattedStakingValues = {
    rewardToken,
    seedId: stakingSeedId,
    sessionInterval: Number(sessionInterval),
    rewardPerSession: parsedRewardPerSession,
    startAt: millisecondsToSeconds(startAtValueOf),
  };
  if (!isEmptyStr(minDeposit.toString())) formattedValues.minDeposit = parseTokenAmount(minDeposit, decimals);
  return formattedValues;
};

export const initialValues: IFormStakingValues = {
  minDeposit: '',
  startAt: '',
  sessionInterval: '60',
  distributionInDay: '',
  distributionAmount: '',
};

export const retrieveStakingData = (
  stakingArray: IFormattedStaking[],
  totalStaked: string,
) => {
  if (!stakingArray.length) return null;
  const firstStaking = stakingArray[0];
  const data = stakingArray.reduce((acc, staking) => {
    if (staking.startAt < acc.startAt) acc.startAt = staking.startAt;
    if (staking.endAt > acc.endAt) acc.endAt = staking.endAt;
    acc.unclaimedReward = Big(acc.unclaimedReward).plus(staking.unclaimedReward).toFixed();
    acc.claimedReward = Big(acc.claimedReward).plus(staking.claimedReward).toFixed();

    if (staking.status !== EFarmStatus.Running) return acc;
    acc.rewardPerSeconds = Big(acc.rewardPerSeconds).plus(staking.rewardPerSeconds).toFixed();
    acc.totalReward = Big(acc.totalReward).plus(staking.totalReward).toFixed();
    acc.apy = Big(acc.apy).plus(staking.apy).toFixed();
    return acc;
  }, {
    startAt: firstStaking.startAt,
    endAt: firstStaking.endAt,
    rewardPerSeconds: ZERO,
    totalReward: ZERO,
    unclaimedReward: ZERO,
    claimedReward: ZERO,
    apy: ZERO,
  });
  const {
    startAt,
    endAt,
    rewardPerSeconds,
    totalReward,
    unclaimedReward,
    claimedReward,
    apy,
  } = data;

  const farms: { [key: string]: IFormattedStaking } = toMap(stakingArray, 'id');
  return {
    rewardTokenId: firstStaking.rewardTokenId,
    startAt,
    endAt,
    rewardPerSeconds,
    apy,
    totalStaked,
    totalReward,
    unclaimedReward,
    claimedReward,
    farms: {
      obj: farms,
      arr: stakingArray,
    },
  };
};

import { t } from 'i18next';

import { ZERO } from 'shared/constant';

interface IParseDate {
  year: string,
  month: string,
  day: string,
  hours: string,
  minutes: string,
  seconds: string,
}

const formatDate = (value: number) => value.toString().padStart(2, ZERO);

export function getParseDate(date: number | string): IParseDate {
  const newDate = new Date(date);
  const dateObj = {
    year: newDate.getUTCFullYear().toString(),
    month: formatDate(newDate.getUTCMonth() + 1),
    day: formatDate(newDate.getUTCDate()),
    hours: formatDate(newDate.getUTCHours()),
    minutes: formatDate(newDate.getUTCMinutes()),
    seconds: formatDate(newDate.getUTCSeconds()),
  };
  return dateObj;
}

function determineTimeUnit(time: number): number {
  const logTime = Math.log10(time);
  const LOG_SECONDS = 3;
  const LOG_NANOSECONDS = -6;

  if (logTime >= LOG_SECONDS) {
    return time * 10 ** LOG_SECONDS;
  } if (logTime <= LOG_NANOSECONDS) {
    return time * 10 ** -LOG_SECONDS;
  }
  return time;
}

export const parseDate = (date: number) => {
  const parseTime = determineTimeUnit(date);
  const {
    year,
    month,
    day,
    hours,
    minutes,
    seconds,
  } = getParseDate(parseTime);
  return t('staking.date', {
    year,
    month,
    day,
    hours,
    minutes,
    seconds,
  });
};

import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material/styles';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'styled-components';

import { DataProvider } from 'providers/DataProvider';
import { ServiceProvider } from 'providers/ServiceProvider';
import { WalletSelectorProvider } from 'providers/WalletSelectorProvider';
import AppRoutes from 'routes';
import theme from 'shared/theme';

import '@near-wallet-selector/modal-ui/styles.css';
import './services/translation';
import './index.css';

const darkThemeMUI = createTheme({
  palette: {
    mode: 'dark',
  },
});

function AppWrapper() {
  return (
    <ThemeProvider theme={theme}>
      <MUIThemeProvider theme={darkThemeMUI}>
        <WalletSelectorProvider>
          <ServiceProvider>
            <DataProvider>
              <AppRoutes />
            </DataProvider>
          </ServiceProvider>
        </WalletSelectorProvider>
      </MUIThemeProvider>
    </ThemeProvider>
  );
}

createRoot(
  document.getElementById('root')!,
).render(<AppWrapper />);

import {
  IFarmInfo, StakingContractViewMethod, SeedInfo, Action, StakingChangeMethods,
} from 'services/interfaces';
import RPCProviderService from 'services/RPCProviderService';
import { STORAGE_TO_CREATE_FARM } from 'shared/constant';
import { IFormattedStakingValues } from 'shared/interfaces';

export default class StakingContract {
  readonly contractId: string;

  private provider: RPCProviderService;

  constructor(contractId: string, provider: RPCProviderService) {
    this.contractId = contractId;
    this.provider = provider;
  }

  async getListFarmsBySeed(seedId: string): Promise<Array<IFarmInfo> | undefined> {
    return this.provider.viewFunction(StakingContractViewMethod.listFarmsBySeed, this.contractId, {
      seed_id: seedId,
    });
  }

  async getSeedInfo(seedId: string): Promise<SeedInfo | undefined> {
    return this.provider.viewFunction(StakingContractViewMethod.getSeedInfo, this.contractId, {
      seed_id: seedId,
    });
  }

  createStaking(values: IFormattedStakingValues): Action[] {
    return [{
      receiverId: this.contractId,
      functionCalls: [{
        methodName: StakingChangeMethods.createSimpleFarm,
        args: {
          terms: {
            seed_id: values.seedId,
            reward_token: values.rewardToken,
            reward_per_session: values.rewardPerSession,
            session_interval: values.sessionInterval,
            start_at: values.startAt,
          },
          min_deposit: values.minDeposit,
        },
        amount: STORAGE_TO_CREATE_FARM,
      }],
    }];
  }
}

import { useFormikContext } from 'formik';
import { useMemo } from 'react';

import { useData } from 'providers/DataProvider';
import Input from 'shared/components/Input';
import Select from 'shared/components/Input/Select';
import Translate from 'shared/components/Translate';
import { EFromValues, ETypeInput, IFormValues } from 'shared/interfaces';
import { getPoolListForSelect, getFarmListForSelect } from 'shared/utils/getListForSelect';

import styles from './styles';

interface IPrimaryForm {
  isNeedMftStorage: boolean,
  setIsNeedMftStorage: React.Dispatch<React.SetStateAction<boolean>>,
}

export default function DepositForm({
  isNeedMftStorage,
  setIsNeedMftStorage,
}: IPrimaryForm) {
  const {
    values, setFieldValue, getFieldMeta, getFieldProps,
  } = useFormikContext<IFormValues>();
  const {
    farms, pools, prices, tokens,
  } = useData();

  const poolList = useMemo(() => getPoolListForSelect(pools, prices), [pools, prices]);
  const farmList = useMemo(() => getFarmListForSelect(farms, tokens, values.poolId), [farms, tokens, values.poolId]);

  return (
    <styles.Container>
      <styles.Title>
        <styles.JumboIcon />
        <Translate value="title.deposit" />
      </styles.Title>
      <styles.Row>
        <Input
          type={ETypeInput.NUMERICAL}
          name={EFromValues.distributionAmount}
          field={getFieldProps(EFromValues.distributionAmount)}
          meta={getFieldMeta(EFromValues.distributionAmount)}
        />
        <Select
          name={EFromValues.poolId}
          value={values.poolId}
          type={ETypeInput.TEXT}
          optionList={poolList}
          setFieldValue={setFieldValue}
        />
        <Select
          name={EFromValues.farmIndex}
          value={values.farmIndex}
          type={ETypeInput.TEXT}
          optionList={farmList}
          setFieldValue={setFieldValue}
        />
      </styles.Row>
      <styles.Toggle>
        <styles.LabelCheckbox htmlFor="toggle">
          <input
            id="toggle"
            type="checkbox"
            onChange={(e) => setIsNeedMftStorage(e.target.checked)}
            checked={isNeedMftStorage}
          />
          <styles.ToggleSwitch />
        </styles.LabelCheckbox>
        <styles.Description>
          <Translate value="description.mft" />
        </styles.Description>
      </styles.Toggle>
    </styles.Container>
  );
}

import { walletId } from 'services/config';

export const MEDIA_WIDTHS = {
  upToSmall: 600,
  upToMedium: 768,
  upToLarge: 1280,
};

export const DEFAULT_PAGE_LIMIT = 100;
export const LP_TOKEN_DECIMALS = 24;
export const STABLE_LP_TOKEN_DECIMALS = 18;
export const ONE_YOCTO_NEAR = '0.000000000000000000000001';
export const STORAGE_TO_CREATE_FARM = '0.01';

export const STORAGE_TO_REGISTER_FT = '0.1';
export const STORAGE_TO_REGISTER_MFT = '0.01';
export const STORAGE_TO_REGISTER_WNEAR = '0.00125';
export const FARM_ID_KEY = 'FARM_ID';
export const VALID_VALUE_CONTRACT_ID = `.${walletId}`;
export const SECONDS_IN_MILLISECONDS = 1000;
export const NEAR_TOKEN_ID = 'near';
export const ZERO = '0';
export const DEFAULT_GAS = '100000000000000';
export const NEAR_DECIMALS = 24;
export const SHOW_MIN_TOTAL_LIQUIDITY = '1000';
export const DASH = '-';

export const DAYS_A_YEAR = 365;
export const ONE_HUNDRED = 100;
export const SECONDS_IN_A_DAY = 86400;
export const EMPTY_STRING = '';

export enum RequestTypes { GET = 'GET' }
export const HEADERS = { 'Content-type': 'application/json; charset=UTF-8' };

import dayjs from 'dayjs';

import { exchangeContractId } from 'services/config';
import { ITokenMetadata } from 'services/interfaces';
import { IFormattedValues, IFormValues } from 'shared/interfaces';

import calcRewardPerSession from './calcRewardPerSession';
import { parseTokenAmount } from './formatAmount';
import { millisecondsToSeconds } from './index';
import { isEmptyStr } from './stringOperations';

const formatValues = (
  formValues: IFormValues,
  metadata: ITokenMetadata,
  rewardTokenId?: string,
): IFormattedValues => {
  const {
    minDeposit,
    sessionInterval,
    startAt,
    distributionInDay,
    distributionAmount,
    poolId,
    rewardToken,
    farmIndex,
  } = formValues;
  const { decimals } = metadata;

  const seedId = `${exchangeContractId}@${poolId}`;

  const { rewardPerSession } = calcRewardPerSession(distributionInDay, distributionAmount, sessionInterval);
  const parsedRewardPerSession = parseTokenAmount(rewardPerSession, decimals);
  const parsedAmount = parseTokenAmount(distributionAmount, decimals);
  const message = `${exchangeContractId}@${poolId}#${farmIndex}`;

  const startAtValueOf = dayjs(startAt)
    .add(dayjs().utcOffset(), 'minutes').valueOf();

  const formattedValues: IFormattedValues = {
    rewardToken: rewardTokenId || rewardToken,

    poolId,
    seedId,
    sessionInterval: Number(sessionInterval),
    rewardPerSession: parsedRewardPerSession,
    startAt: millisecondsToSeconds(startAtValueOf),

    amount: parsedAmount,
    message,
    mftTokenId: `:${poolId}`,
  };
  if (!isEmptyStr(minDeposit.toString())) formattedValues.minDeposit = parseTokenAmount(minDeposit, decimals);

  return formattedValues;
};

export default formatValues;

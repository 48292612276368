import Big from 'big.js';

import { ITokenPrice, IBalance } from 'providers/interfaces';
import { ZERO, DASH } from 'shared/constant';
import { displayBalance } from 'shared/utils';

export const getCurrentBalance = (
  balances: { [key: string]: IBalance; },
  tokenId: string,
) => {
  const balanceState = balances[tokenId];
  if (!balanceState) return ZERO;
  const { balance, decimal } = balanceState;
  return displayBalance(balance, decimal);
};

export const getCurrentPrice = (
  prices: { [key: string]: ITokenPrice; },
  balances: { [key: string]: IBalance; },
  tokenId: string,
) => {
  const balanceState = balances[tokenId];
  const priceState = prices[tokenId];

  if (!balanceState || !priceState) return DASH;
  const { balance, decimal } = balanceState;
  const { price } = priceState;
  const currentPrice = Big(balance).mul(price).toFixed();
  return displayBalance(currentPrice, decimal);
};

import styled from 'styled-components';

import { ReactComponent as Minus } from 'assets/images/icons/minus.svg';
import { ReactComponent as Plus } from 'assets/images/icons/plus.svg';
import { EFarmStatus } from 'services/interfaces';
import Buttons from 'shared/components/Buttons';
import SpecialContainer from 'shared/components/SpecialContainer';

const Wrapper = styled(SpecialContainer)`
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.farmingTimeBg};
  max-width: 736px;
  width: 100%;
  border-radius: 1.5rem;
  margin: 0;
  padding: 0;
  min-height: 160px;
  ::before{
    border-radius: 1.5rem;
  }
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.farmingBg};
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  min-height: 160px;
  padding: 1.125rem 1.5rem 1.5rem;
  border-radius: 1.5rem;
`;

const Time = styled.div<{ isDynamicEndDate?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  background-color: ${({ theme }) => theme.farmingTimeBg};
  border-radius: 0 0 24px 24px;
  font-style: normal;
  font-weight: 300;
  font-size: .75rem;
  line-height: .875rem;
  span {
    color: ${({ isDynamicEndDate, theme }) => (isDynamicEndDate ? theme.greenText : theme.white)}
  }
`;

const Status = styled.div<{ type: EFarmStatus }>`
  display: flex;
  align-items: center;
  width: fit-content;
  border: 1px solid ${({ theme, type }) => {
    if (type === EFarmStatus.Running) return theme.statusActive;
    if (type === EFarmStatus.Created) return theme.statusPending;
    return theme.statusEnded;
  }};
  
  box-sizing: border-box;
  border-radius: 4px;
  color: ${({ theme, type }) => {
    if (type === EFarmStatus.Running) return theme.statusActive;
    if (type === EFarmStatus.Created) return theme.statusPending;
    return theme.statusEnded;
  }};
  user-select: none;
  font-style: normal;
  font-weight: 400;
  font-size: .75rem;
  line-height: .875rem;
  max-height: 22px;
  padding: 0.25rem;
`;

const UpperRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  p {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 0.5rem;
  & > div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    :first-child, 
    :nth-child(3n+1) {
      align-items: flex-start;
    }
    :nth-child(3n+2) {
      align-items: flex-end;
    }
    :nth-child(3n) {
      align-items: center;
      grid-area: 2 / 1 / 3 / 3;
    }
  }
`;

const ElementTitle = styled.div`
  display: flex;
  font-weight: 400;
  font-size: .75rem;
  line-height: .875rem;
  color: ${({ theme }) => theme.grey};
`;

const ElementValue = styled.div<{ color?: string }>`
  color: ${({ theme, color }) => color || theme.white};
  font-weight: 400;
  font-size: .75rem;
  line-height: .875rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10rem;
`;

const Button = styled.button`
  outline: none;
  border: none;
  display: flex;
  padding: 0.5rem 0;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  cursor: pointer;
  color: ${({ theme }) => theme.grey};
  background-color: rgb(${({ theme }) => theme.darkGrey});
  :hover {
    background-color: rgba(${({ theme }) => theme.darkGrey}, 0.6);
  }
  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const StyledInput = styled.input<{ error?: boolean }>`
  font-size: 1rem;
  outline: none;
  border: none;
  flex: 1 1 auto;
  color: ${({ theme }) => theme.white};
  background-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid ${({ error, theme }) => (error
    ? theme.error
    : theme.greyOp04)};
  border-radius: 0.75rem;
  :focus-within {
    border: 1px solid ${({ theme }) => theme.pink};
  }
  ::placeholder {
    color: ${({ theme }) => theme.greyOp04};
  }
`;

const ActionButton = styled(Buttons.Primary)`
  font-size: 0.75rem;
  padding: 0.5rem;
  min-height: auto;
`;

const Error = styled.div`
  color: ${({ theme }) => theme.error};
  left: 1rem;
  font-weight: 300;
  font-size: .75rem;
  line-height: .875rem;
  margin-top: .5rem;
`;

export default {
  Wrapper,
  Container,
  Time,
  Status,
  UpperRow,
  Table,
  ElementTitle,
  ElementValue,
  Button,
  Plus,
  Minus,
  StyledInput,
  ActionButton,
  Error,
};

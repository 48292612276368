import { FARM_ID_KEY } from 'shared/constant';
import { EFromValues, IFormValues } from 'shared/interfaces';

const createInitialConfig = (): IFormValues => {
  const message = localStorage.getItem(FARM_ID_KEY);
  const poolId = message ? message.slice(message.lastIndexOf('@') + 1, message.lastIndexOf('#')) : '';
  const farmIndex = message ? message.slice(message.lastIndexOf('#') + 1) : '';
  return {
    rewardToken: '',

    poolId,
    minDeposit: '',
    startAt: '',
    sessionInterval: '60',
    distributionInDay: '',
    distributionAmount: '',
    farmIndex,
  };
};

export const initialConfig = createInitialConfig();

export const formTitles = {
  [EFromValues.rewardToken]: 'Reward Token',

  [EFromValues.poolId]: 'Pool',
  [EFromValues.minDeposit]: 'Min Deposit',
  [EFromValues.startAt]: 'Start At',
  [EFromValues.sessionInterval]: 'Session Interval',
  [EFromValues.distributionInDay]: 'Distribution duration in days',
  [EFromValues.distributionAmount]: 'Distribution amount',

  [EFromValues.farmIndex]: 'Farm Index',
};

export const formPlaceholders = {
  [EFromValues.rewardToken]: 'token.jumbo_exchange.near',

  [EFromValues.poolId]: '4: WNEAR/JUMBO - $41602.5686',
  [EFromValues.minDeposit]: 'optional parament',
  [EFromValues.startAt]: '1655412424',
  [EFromValues.sessionInterval]: '60',
  [EFromValues.distributionInDay]: '333',
  [EFromValues.distributionAmount]: '10000',

  [EFromValues.farmIndex]: '0',
};

export const formTooltip = {
  [EFromValues.rewardToken]: 'tooltip.rewardToken',

  [EFromValues.poolId]: 'tooltip.poolId',
  [EFromValues.minDeposit]: 'tooltip.minDeposit',
  [EFromValues.startAt]: 'tooltip.startAt',
  [EFromValues.sessionInterval]: 'tooltip.sessionInterval',
  [EFromValues.distributionInDay]: 'tooltip.distributionInDay',
  [EFromValues.distributionAmount]: 'tooltip.distributionAmount',

  [EFromValues.farmIndex]: 'tooltip.farmIndex',
};

import { ITokenPrice } from 'providers/interfaces';
import {
  helperUrl, indexerUrl, jumboAddress, wNearAddress,
} from 'services/config';
import { RequestTypes, HEADERS } from 'shared/constant';

const JUMBO_INITIAL_DATA = {
  id: jumboAddress,
  decimal: 18,
  symbol: 'JUMBO',
  price: '0',
};

const NEAR_INITIAL_DATA = {
  id: wNearAddress,
  decimal: 24,
  symbol: 'WNEAR',
  price: '0',
};

const pricesInitialState = {
  [wNearAddress]: NEAR_INITIAL_DATA,
  [jumboAddress]: JUMBO_INITIAL_DATA,
};

export class ApiService {
  static async getPriceData(): Promise<{ [key: string]: ITokenPrice }> {
    const url = `${indexerUrl}/token-prices`;
    try {
      const pricesData = await fetch(url, {
        method: RequestTypes.GET,
        headers: HEADERS,
      })
        .then((res) => res.json())
        .then((list) => list);
      return pricesData.reduce((acc: { [key: string]: ITokenPrice }, item: ITokenPrice) => ({
        ...acc, [item.id]: item,
      }), {});
    } catch (e) {
      console.warn(`Error ${e} while loading prices from ${url}`);
      return pricesInitialState;
    }
  }

  static async getNearPrice(): Promise<string | null> {
    const url = `${helperUrl}/fiat`;
    try {
      const pricesData = await fetch(url, {
        method: RequestTypes.GET,
        headers: HEADERS,
      })
        .then((res) => res.json())
        .then((list) => list.near.usd || 0);
      return pricesData;
    } catch (e) {
      console.warn(`Error ${e} while loading near price from ${url}`);
      return null;
    }
  }
}

export default new ApiService();

import styled from 'styled-components';

const SpecialContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  min-width: 310px;
  max-width: 738px;
  height: fit-content;
  min-height: 150px;

  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.backgroundCard};
  box-shadow: 0px 32px 72px -12px ${({ theme }) => theme.boxShadowCard};
  border-radius: 36px;
  padding: 1.5rem;
  ::before {
    content: '';
    position: absolute;
    top: -1px;
    bottom: 0;
    left: -1px;
    right: -1px;
    background: ${({ theme }) => theme.specialBorderCard};
    border-radius: 36px;
    z-index: -1;
  }
  transition: all .2s ease-out;
`;

export default SpecialContainer;

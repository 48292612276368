import styled from 'styled-components';

const DynamicContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1;
  flex-shrink: 1;
`;

const Wrapper = styled.div`
  display: flex;
  max-width: 46.125rem;
  width: 100%;
  justify-content: space-between;
  margin-top: 1rem;
  gap: 1rem;
`;

const StakingList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default {
  DynamicContainer,
  StakingList,
  Wrapper,
};

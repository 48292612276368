import { useData } from 'providers/DataProvider';
import { getToken } from 'providers/helpers';
import { useFromStakingProvider } from 'providers/LocalProviders/StakingProvider';

import StakingCard from '../StakingCard';
import Statistics from '../Statistics';
import styles from './styles';
import convertFormStakingValues from './utils';

export default function StickyContainer() {
  const { createStakingForm } = useFromStakingProvider();
  const { tokens, staking } = useData();
  const rewardToken = getToken(staking.rewardTokenId, tokens);
  const metadata = rewardToken?.metadata;
  if (!rewardToken || !metadata) return null;
  const convertedStaking = convertFormStakingValues(
    createStakingForm.values,
    staking.farms.arr.length,
    metadata.decimals,
    rewardToken.contractId,
    staking.totalStaked,
  );
  return (
    <styles.Container>
      <Statistics metadata={metadata} />
      {convertedStaking && <StakingCard staking={convertedStaking} token={rewardToken} />}
    </styles.Container>
  );
}

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField/TextField';
import { useField } from 'formik';

import { ITranslationKeys } from 'services/translation';
import { EFromValues, ETypeInput } from 'shared/interfaces';
import { formPlaceholders, formTitles, formTooltip } from 'shared/utils';

import Tooltip from '../Tooltip';
import styles from './styles';

interface ISelect {
  value: string,
  name: EFromValues,
  type: ETypeInput,
  optionList: Array<{ amount: string, symbols: string, id: number }>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
}

export default function Select({
  value,
  name,
  type,
  optionList,
  setFieldValue,
}: ISelect) {
  const props = {
    value,
    name,
    type,
    optionList,
  };
  const [field, meta] = useField(props);
  const error = Boolean(meta.touched && meta.error);
  return (
    <Autocomplete
      style={{ width: '100%' }}
      disablePortal
      options={optionList}
      getOptionLabel={({ id, symbols, amount }) => `${id}: ${symbols} - ${amount}`}
      isOptionEqualToValue={(option, list) => option.id === list.id}
      renderOption={(renderProps, { id, symbols, amount }) => (
        <li {...renderProps}>
          {`${id}: ${symbols} - ${amount}`}
        </li>
      )}
      renderInput={(params) => (
        <styles.Container>
          <styles.Title>
            {formTitles[name]}
            <Tooltip title={formTooltip[name] as ITranslationKeys} />
          </styles.Title>
          <styles.InputWrapper error={!!error}>
            <TextField
              {...params}
              {...field}
              placeholder={formPlaceholders[name]}
              variant="standard"
            />
          </styles.InputWrapper>
          {
          error && (
            <styles.Error>
              {meta.touched && meta.error}
            </styles.Error>
          )
        }
        </styles.Container>
      )}
      onChange={(_, listElement) => {
        if (!listElement) return;
        setFieldValue(name, listElement?.id);
      }}
    />
  );
}

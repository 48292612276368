import Translate from '../Translate';
import styles from './styles';

export default function Footer() {
  return (
    <styles.Container>
      <styles.LeftContainer>
        <styles.HapiButton
          href=""
          target="_blank"
          rel="noreferrer"
        >
          <styles.HapiLogoContainer>
            <styles.HapiLogo />
          </styles.HapiLogoContainer>
          <styles.HapiTitle>
            <Translate value="footer.protectBy" />
            <span>
              <Translate value="footer.hapiProtocol" />
            </span>
          </styles.HapiTitle>
        </styles.HapiButton>
      </styles.LeftContainer>
      <styles.TextContainer>
        <Translate value="footer.copyright" />
      </styles.TextContainer>
    </styles.Container>
  );
}

import styled from 'styled-components';

import { ReactComponent as JumboIcon } from 'assets/images/jumbo-token-logo.svg';
import Buttons from 'shared/components/Buttons';
import SpecialContainer from 'shared/components/SpecialContainer';

const Container = styled(SpecialContainer)`
  align-self: center;
  width: 100%;
  margin-bottom: 2rem;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    margin-right: .5rem;
  }
`;

const Button = styled(Buttons.Secondary)<{ isSuccess?: boolean }>`
  width: 100%;
  margin-top: 1rem;
  border-color: ${({ theme, isSuccess }) => (isSuccess ? theme.darkGreen : theme.darkPink)};
  :hover {
    border-color: ${({ theme, isSuccess }) => (isSuccess ? theme.green : theme.pinkHover)};
  }
`;

const Error = styled.span`
  color: ${({ theme }) => theme.error};
  font-weight: 300;
  font-size: .75rem;
  line-height: .875rem;
  align-self: flex-start;
  margin-top: 1rem;
  margin-left: 1rem;
`;

const TitleMetadata = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > img {
    margin-left: .25rem;
    border-radius: 12px;
    height: 19px;
    width: 19px;
  }
`;

export default {
  Container,
  Title,
  JumboIcon,
  Button,
  Error,
  TitleMetadata,
};

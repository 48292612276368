import { Formik } from 'formik';
import { useCallback, useState } from 'react';

import styles from 'pages/styles';
import { useData } from 'providers/DataProvider';
import { getToken } from 'providers/helpers';
import { useService } from 'providers/ServiceProvider';
import { useWalletData } from 'providers/WalletSelectorProvider';
import { exchangeContractId } from 'services/config';
import { DepositForm } from 'shared/components/FormComponent';
import ToastService from 'shared/components/Toast';
import Translate from 'shared/components/Translate';
import { IFormValues } from 'shared/interfaces';
import {
  initialConfig,
  formatValues,
  validationTransferTokenSchema,
} from 'shared/utils';

export default function RewardTokenPage() {
  const { isSignedIn } = useWalletData();
  const { transferRewardToken } = useService();
  const { tokens, farms } = useData();
  const [isNeedMftStorage, setIsNeedMftStorage] = useState<boolean>(false);

  const handleSubmit = useCallback((values: IFormValues) => {
    const farmId = `${exchangeContractId}@${values.poolId}#${values.farmIndex}`;
    const farm = farms[farmId];
    const token = getToken(farm?.rewardTokenId, tokens);
    if (!farm || !token || !token.metadata) return;

    const formattedValues = formatValues(values, token.metadata, token.contractId);
    if (!formattedValues) return;
    ToastService.loading('toast.transferRewardToken');
    transferRewardToken(formattedValues, token, isNeedMftStorage);
  }, [isNeedMftStorage, tokens, transferRewardToken, farms]);

  return (
    <styles.From>
      <Formik
        initialValues={initialConfig}
        validationSchema={validationTransferTokenSchema}
        onSubmit={handleSubmit}
      >
        <styles.FormContainer>
          <DepositForm
            isNeedMftStorage={isNeedMftStorage}
            setIsNeedMftStorage={setIsNeedMftStorage}
          />
          <styles.Button
            type="submit"
            disabled={!isSignedIn}
          >
            <Translate value="action.transferRewardToken" />
          </styles.Button>
        </styles.FormContainer>
      </Formik>
    </styles.From>
  );
}

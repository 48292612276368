import Big from 'big.js';

import { ZERO } from 'shared/constant';

const BASE = 10;
Big.RM = Big.roundDown;
Big.DP = 30;
const MIN_BALANCE = '0.0001';
const MIN_AMOUNT = '0.00001';

export const formatTokenAmount = (value: string, decimals: number, precision?: number): string => value
  && Big(value).div(Big(BASE).pow(decimals)).toFixed(precision && precision);

export const parseTokenAmount = (value: string, decimals: number): string => value
  && Big(value).times(Big(BASE).pow(decimals)).toFixed(0);

export const removeTrailingZeros = (amount: string) => {
  if (amount.includes('.') || amount.includes(',')) {
    return amount.replace(/\.?0*$/, '');
  }
  return amount;
};

export const displayPriceWithSpace = (str: string) => str.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const displayAmount = (
  {
    amount,
    decimals,
    precision = 5,
    isAmountWithSpace = false,
  }:{
    amount: string,
    decimals?: number,
    precision?: number,
    isAmountWithSpace?: boolean
  },
): string => {
  const formateAmount = formatTokenAmount(amount, decimals || 0);
  const amountBig = Big(formateAmount);
  if (amountBig.eq(ZERO)) return ZERO;
  if (amountBig.lte(MIN_AMOUNT)) return `>${MIN_AMOUNT}`;
  const newAmount = removeTrailingZeros(amountBig.toFixed(precision));
  if (isAmountWithSpace) return displayPriceWithSpace(newAmount);
  return newAmount;
};

export const displayBalance = (amount: string, decimals?: number, precision = 3): string => {
  const formateAmount = formatTokenAmount(amount, decimals || 0);
  const amountBig = Big(formateAmount);
  if (amountBig.eq(ZERO)) return ZERO;
  if (amountBig.lte(MIN_BALANCE)) return `>${MIN_BALANCE}`;
  return removeTrailingZeros(amountBig.toFixed(precision));
};

import {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';

import { exchangeContractId, farmContractId, stakingContractId } from 'services/config';
import { PoolContract, FarmContract, FungibleTokenContract } from 'services/contract';
import StakingContract from 'services/contract/StakingContract';
import { IFormattedValues, IFormattedStakingValues } from 'shared/interfaces';

import { ServiceContextType } from './interfaces';
import { useWalletData } from './WalletSelectorProvider';

const ServiceContextHOC = createContext<ServiceContextType>({} as ServiceContextType);

export function ServiceProvider({ children }:{ children: JSX.Element }) {
  const { RPCProvider, accountId, requestSignTransactions } = useWalletData();

  const [poolContract, setPoolContract] = useState<PoolContract | undefined>();
  const [farmContract, setFarmContract] = useState<FarmContract | undefined>();
  const [stakingContract, setStakingContract] = useState<StakingContract | undefined>();

  useEffect(() => {
    const poolContractInstance = new PoolContract(accountId, exchangeContractId, RPCProvider);
    const farmContractInstance = new FarmContract(accountId, farmContractId, RPCProvider);
    const stakingContractInstance = new StakingContract(stakingContractId, RPCProvider);
    setPoolContract(poolContractInstance);
    setFarmContract(farmContractInstance);
    setStakingContract(stakingContractInstance);
  }, [RPCProvider, accountId]);

  const createSimpleFarm = useCallback(async (formValues: IFormattedValues) => {
    if (!farmContract) return;
    const transaction = farmContract.createSimpleFarm(formValues);
    await requestSignTransactions(transaction);
  }, [farmContract, requestSignTransactions]);

  const transferRewardToken = useCallback(async (
    formValues: IFormattedValues,
    token: FungibleTokenContract,
    isNeedMftStorage: boolean,
  ) => {
    if (!farmContract || !poolContract) return;
    const transaction = await farmContract.transferRewardToken(token, formValues.amount, formValues.message);
    if (isNeedMftStorage) {
      const registerMftToken = poolContract.registerTokens(formValues.mftTokenId, farmContractId);
      await requestSignTransactions(registerMftToken.concat(transaction));
      return;
    }
    await requestSignTransactions(transaction);
  }, [farmContract, poolContract, requestSignTransactions]);

  const transferStakingToken = useCallback(async (
    amount: string,
    stakingId: string,
    token: FungibleTokenContract,
  ) => {
    if (!stakingContract) return;
    const transaction = await token.transfer(
      {
        accountId: stakingContract.contractId,
        receiverId: token.contractId,
        amount,
        message: stakingId,
      },
    );
    await requestSignTransactions(transaction);
  }, [requestSignTransactions, stakingContract]);

  const createStaking = useCallback(async (formValues: IFormattedStakingValues) => {
    if (!stakingContract) return;
    const transaction = stakingContract.createStaking(formValues);
    await requestSignTransactions(transaction);
  }, [stakingContract, requestSignTransactions]);

  const serviceData = useMemo(() => ({
    farmContract,
    poolContract,
    createSimpleFarm,
    transferRewardToken,
    stakingContract,
    transferStakingToken,
    createStaking,
  }), [
    farmContract,
    poolContract,
    createSimpleFarm,
    transferRewardToken,
    stakingContract,
    transferStakingToken,
    createStaking,
  ]);

  return (
    <ServiceContextHOC.Provider value={serviceData}>
      {children}
    </ServiceContextHOC.Provider>
  );
}

export const useService = () => useContext(ServiceContextHOC);

import { useData } from 'providers/DataProvider';
import { FungibleTokenContract } from 'services/contract';

import styles from './styles';
import { getCurrentBalance, getCurrentPrice } from './utils';

export default function Token({ token }: { token: FungibleTokenContract }) {
  const { prices, balances } = useData();
  const tokenId = token.contractId;
  const { metadata } = token;
  if (!metadata) return null;
  return (
    <styles.Container>
      <styles.LogoContainer>
        <img src={metadata.icon} alt={metadata.symbol} />
      </styles.LogoContainer>
      <styles.DescriptionBlock>
        <styles.Title>
          <div>
            {metadata.symbol}
            <styles.TokenId>{tokenId}</styles.TokenId>
          </div>
          <div>{getCurrentBalance(balances, tokenId)}</div>
        </styles.Title>
        <styles.Subtitle>
          <div>{metadata.name}</div>
          <div>{getCurrentPrice(prices, balances, tokenId)}</div>
        </styles.Subtitle>
      </styles.DescriptionBlock>
    </styles.Container>
  );
}

import { initialDataState } from 'providers/DataProvider';
import { IStaking } from 'providers/interfaces';
import { stakingSeedId } from 'services/config';
import StakingContract from 'services/contract/StakingContract';
import { ZERO } from 'shared/constant';
import { formatStaking, retrieveStakingData } from 'shared/utils/staking';

export default async function retrieveStaking(
  stakingContract: StakingContract,
): Promise<IStaking | null> {
  const [
    listStaking,
    seedInfo,
  ] = await Promise.all(
    [
      stakingContract.getListFarmsBySeed(stakingSeedId),
      stakingContract.getSeedInfo(stakingSeedId),
    ],
  );

  if (!listStaking || !seedInfo) return initialDataState.staking;
  const listStakingArray = listStaking.map((staking) => formatStaking(staking, seedInfo?.amount || ZERO));

  return retrieveStakingData(listStakingArray, seedInfo?.amount || ZERO);
}

import Big from 'big.js';
import { formatNearAmount } from 'near-api-js/lib/utils/format';

import nearIcon from 'assets/images/icons/near-token-icon.svg';
import wNearIcon from 'assets/images/icons/wnear-icon.svg';
import { usn, wNearAddress } from 'services/config';
import {
  Action,
  FTChangeMethods,
  FTViewMethods,
  IFungibleTokenContract,
  IStorageBalance,
  IStorageBalanceBounds,
  ITokenMetadata,
} from 'services/interfaces';
import { IRPCProviderService } from 'services/RPCProviderService';
import {
  EMPTY_STRING,
  NEAR_DECIMALS,
  NEAR_TOKEN_ID, ONE_YOCTO_NEAR, STORAGE_TO_REGISTER_FT, STORAGE_TO_REGISTER_WNEAR, ZERO,
} from 'shared/constant';

const NEAR_TOKEN = {
  decimals: NEAR_DECIMALS,
  icon: nearIcon,
  name: 'Near token',
  version: '0',
  symbol: 'NEAR',
  reference: '',
};

export default class FungibleTokenContract implements IFungibleTokenContract {
  readonly contractId: string;

  private provider: IRPCProviderService;

  metadata: ITokenMetadata | null = null;

  constructor(provider: IRPCProviderService, contractId: string) {
    this.provider = provider;
    this.contractId = contractId;
  }

  async getMetadata(): Promise<ITokenMetadata | undefined> {
    try {
      if (this.contractId === NEAR_TOKEN_ID) {
        this.metadata = { ...NEAR_TOKEN };
        return NEAR_TOKEN;
      }

      const metadata = await this.provider.viewFunction(FTViewMethods.ftMetadata, this.contractId);
      if (!metadata) return undefined;
      if (this.contractId === wNearAddress) metadata.icon = wNearIcon;

      this.metadata = { ...metadata };
      return metadata;
    } catch (e) {
      console.warn(`Error while loading ${this.contractId}`);
    }
    return undefined;
  }

  async getBalanceOf({ accountId }: { accountId: string }): Promise<string> {
    try {
      if (this.contractId === NEAR_TOKEN_ID) {
        const account = await this.provider.viewAccount(
          accountId,
        );
        return account?.amount;
      }
      return await this.provider
        .viewFunction(FTViewMethods.ftBalanceOf, this.contractId, { account_id: accountId });
    } catch (error) {
      return ZERO;
    }
  }

  async getMinStorageBalanceBounce(): Promise<string> {
    try {
      const storageBalanceBounce: IStorageBalanceBounds = await this.provider.viewFunction(
        FTViewMethods.storageBalanceBounds,
        this.contractId,
      );
      return storageBalanceBounce.min;
    } catch (e) {
      console.warn(`Error: ${e} while you try to get min storage balance bounce.`);
      return ZERO;
    }
  }

  async getStorageBalanceOf({ accountId } : { accountId: string }): Promise<IStorageBalance | undefined> {
    return this.provider
      .viewFunction(FTViewMethods.storageBalanceOf, this.contractId, { account_id: accountId });
  }

  async checkStorageBalance({ accountId }: { accountId: string }): Promise<Action[]> {
    try {
      if (this.contractId === NEAR_TOKEN_ID || this.contractId === usn) return [];
      const storageBalance = await this.getStorageBalanceOf({ accountId });
      const minStorageBalanceBounds = await this.getMinStorageBalanceBounce();
      if (!storageBalance
        || (!Big(minStorageBalanceBounds).eq(ZERO) && Big(storageBalance.total).lt(minStorageBalanceBounds))
      ) {
        const defaultStorageAmount = this.contractId === wNearAddress
          ? STORAGE_TO_REGISTER_WNEAR
          : STORAGE_TO_REGISTER_FT;

        let storageAmount = defaultStorageAmount;
        if (Big(minStorageBalanceBounds).gt(storageBalance?.total || ZERO)) {
          const newStorageAmount = Big(minStorageBalanceBounds).minus(storageBalance?.total || ZERO).toFixed();
          const formattedAmount = formatNearAmount(newStorageAmount);
          storageAmount = formattedAmount;
        }

        return [{
          receiverId: this.contractId,
          functionCalls: [{
            methodName: FTChangeMethods.storageDeposit,
            args: {
              registration_only: true,
              account_id: accountId,
            },
            amount: storageAmount,
          }],
        }];
      }
      return [];
    } catch (e) {
      return [];
    }
  }

  async transfer({
    accountId,
    receiverId,
    amount,
    message = EMPTY_STRING,
  }:
  {
    accountId: string,
    receiverId: string,
    amount: string,
    message?: string,
  }): Promise<Action[]> {
    const transactions: Action[] = [];
    const checkStorage = await this.checkStorageBalance({ accountId });
    transactions.push(...checkStorage);
    transactions.push({
      receiverId,
      functionCalls: [{
        methodName: FTChangeMethods.ftTransferCall,
        args: {
          receiver_id: accountId,
          amount,
          msg: message,
        },
        amount: ONE_YOCTO_NEAR,
      }],
    });
    return transactions;
  }
}

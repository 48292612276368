import Big from 'big.js';

import { getPriceData, getToken } from 'providers/helpers';
import { IFarm, IPool, ITokenPrice } from 'providers/interfaces';
import { FungibleTokenContract } from 'services/contract';
import { EFarmStatus } from 'services/interfaces';
import { SHOW_MIN_TOTAL_LIQUIDITY } from 'shared/constant';

import { formatTokenAmount, isNotNullOrUndefined } from './index';

export const getPoolListForSelect = (
  pools: { [key: number]: IPool },
  prices: { [key: string]: ITokenPrice },
) => (
  Object.values(pools)
    .sort((a, b) => Big(b.totalLiquidity).minus(a.totalLiquidity).toNumber())
    .map((pool) => {
      const [firstTokenId, secondTokenId] = pool.tokenAccountIds;
      const firstToken = getPriceData(firstTokenId, prices);
      const secondToken = getPriceData(secondTokenId, prices);
      if (!firstToken || !secondToken) return null;
      const symbols = `${firstToken.symbol}/${secondToken.symbol}`;
      if (Big(pool.totalLiquidity).lte(SHOW_MIN_TOTAL_LIQUIDITY)) return null;
      return {
        amount: `$${pool.totalLiquidity}`,
        symbols,
        id: pool.id,
      };
    })
    .filter(isNotNullOrUndefined)
);

export const getFarmListForSelect = (
  farms: { [key: number]: IFarm },
  tokens: { [key: string]: FungibleTokenContract },
  poolId: string,
) => (
  Object.values(farms)
    .filter((farm) => (
      farm.poolId === Number(poolId)
    && (farm.status === EFarmStatus.Created || farm.status === EFarmStatus.Running)
    ))
    .map((farm) => {
      const token = getToken(farm.rewardTokenId, tokens);
      if (!token?.metadata) return null;
      const formattedRewardPerSession = formatTokenAmount(farm.rewardPerSession, token.metadata.decimals, 4);
      return {
        amount: formattedRewardPerSession,
        symbols: token.metadata.symbol,
        id: Number(farm.farmIndex),
      };
    })
    .filter(isNotNullOrUndefined)
);

import { Slide, toast, ToastOptions } from 'react-toastify';
import styled from 'styled-components';

import { ITranslationKeys } from 'services/translation';
import { colors } from 'shared/theme';

import Translate from '../Translate';

const Link = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.white};
  & > p {
    color: ${({ theme }) => theme.grey};
    margin: 0;
    font-size: .75rem;
  }
`;

const getOptions = (isError = false): ToastOptions => ({
  theme: 'dark',
  transition: Slide,
  closeOnClick: false,
  style: {
    background: isError ? colors.warningBg : colors.backgroundCard,
    boxShadow: '0px 0px 10px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '12px',
  },
});

const generateContent = (action: ITranslationKeys, href?: string) => (href
  ? (
    <Link href={href} target="_blank" rel="noreferrer">
      <Translate value={action} />
      <p>
        <Translate value="action.openTransaction" />
      </p>
    </Link>
  ) : <Translate value={action} />);

export default class ToastService {
  static loading(action: ITranslationKeys): void {
    toast.dismiss();
    const options = getOptions();
    toast.loading(
      <Translate
        value="toast.inProgress"
        interpolation={{
          action: `$t(${action})`,
        }}
      />,
      options,
    );
  }

  static success(action: ITranslationKeys, href?: string): void {
    toast.dismiss();
    const content = generateContent(action, href);
    const options = getOptions();
    toast.success(content, options);
  }

  static error(action: ITranslationKeys, href?: string): void {
    toast.dismiss();
    const content = generateContent(action, href);
    const isError = true;
    const options = getOptions(isError);
    toast.error(content, options);
  }
}

import styled from 'styled-components';

import { ReactComponent as WalletLogo } from 'assets/images/icons/wallet.svg';
import { ReactComponent as JumboLogo } from 'assets/images/jumbo-logo.svg';

import Buttons from '../Buttons';

const Header = styled.div`
  transition: all 1s ease;

  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.white};
  position: relative;
  margin: 1.5rem 0;
  padding: 0 1rem;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > svg {
    margin-right: 0.438rem;
  }
`;

const LogoTitle = styled.h1`
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.438rem;
  color: ${({ theme }) => theme.white};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
`;

const NavBar = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  justify-content: center;
  color: white;
  & > a {
    text-decoration: none;
  }
`;

const NavButton = styled.button<{ isActive?: boolean }>`
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.438rem;
  color: ${({ theme, isActive }) => (isActive ? theme.pink : theme.grey)};
  margin: 0 12px;
  padding: 0;
  position: relative;
  transition: all .3s ease;
  :after {
    content: '';
    transition: all .3s ease;
    position: absolute;
    height: 3px;
    top: 30px;
    border-radius: 1.47856px;
    width: ${({ isActive }) => (isActive ? '36px' : '0')};
    background: ${({ theme, isActive }) => (isActive ? theme.pink : theme.grey)};
    ${({ theme, isActive }) => theme.mediaWidth.upToSmall`
      top: 25px;
      height: 2px;
      width: ${(isActive ? '24px' : '0')};
  `}
  }
  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.pink}
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1rem;
    line-height: 1.188rem;
    margin: 0 8px;
  `}
`;

const Button = styled(Buttons.Primary)<{ bg?: string }>`
  font-weight: 600;
  font-size: .75rem;
  line-height: .938rem;
  background-color: ${({ theme, bg }) => (bg || theme.pink)};
  & > svg {
    margin-right: .5rem;
  }
`;
export default {
  Header,
  LogoContainer,
  LogoTitle,
  JumboLogo,
  NavBar,
  NavButton,
  Button,
  WalletLogo,
};

import Big from 'big.js';
import { useState } from 'react';

import { useData } from 'providers/DataProvider';
import { IFormattedStaking } from 'providers/interfaces';
import { IStakeInput, useFromStakingProvider } from 'providers/LocalProviders/StakingProvider';
import { useService } from 'providers/ServiceProvider';
import { useWalletData } from 'providers/WalletSelectorProvider';
import { FungibleTokenContract } from 'services/contract';
import { EFarmStatus } from 'services/interfaces';
import Translate from 'shared/components/Translate';
import { ZERO } from 'shared/constant';
import { parseTokenAmount, parseDate, formatTokenAmount } from 'shared/utils';

import styles from './styles';
import { FarmStatusLocales, calcFarmData } from './utils';

export default function StakingCard(
  {
    staking,
    token,
  }: {
    staking: IFormattedStaking,
    token: FungibleTokenContract
  },
) {
  const { isSignedIn } = useWalletData();
  const { transferStakingToken } = useService();
  const { balances } = useData();
  const { stakingInputs, setStakingInputs } = useFromStakingProvider();
  const [showInput, setShowInput] = useState<boolean>(false);
  if (!token || !token.metadata) return null;
  const { decimals, symbol } = token.metadata;
  const {
    id,
    stakeId,
    status,
    startAt,
    endAt,
  } = staking;
  const farmsInputData: IStakeInput | undefined = stakingInputs[id];
  const farmData = calcFarmData(staking, farmsInputData, decimals, symbol);
  const sendToken = async () => {
    const parsedAmount = parseTokenAmount(farmsInputData.depositAmount, decimals);
    await transferStakingToken(parsedAmount, staking.stakeId, token);
  };
  const balanceStage = balances[token.contractId];
  const readableBalance = formatTokenAmount(balanceStage.balance, decimals);
  const insufficientBalance = Big(farmsInputData?.depositAmount || ZERO).gt(readableBalance);
  const disableButton = !isSignedIn
    || Big(farmsInputData?.depositAmount || ZERO).eq(ZERO)
    || insufficientBalance;
  return (
    <styles.Wrapper>
      <styles.Container>
        <styles.UpperRow>
          <p>{stakeId}</p>
          <styles.Status type={status}>{FarmStatusLocales[status]}</styles.Status>
        </styles.UpperRow>
        <styles.Table>
          {farmData.map(({ title, value, color }) => (
            <div key={title}>
              <styles.ElementTitle>{title}</styles.ElementTitle>
              <styles.ElementValue color={color}>{value}</styles.ElementValue>
            </div>
          ))}
        </styles.Table>
        <styles.Button onClick={() => setShowInput(!showInput)} disabled={staking.status === EFarmStatus.Ended}>
          {showInput ? <styles.Minus /> : <styles.Plus />}
        </styles.Button>
        {showInput && farmsInputData && (
          <>
            <div>
              <styles.StyledInput
                value={farmsInputData.depositAmount}
                onChange={(e) => setStakingInputs(
                  {
                    ...stakingInputs,
                    [id]: {
                      ...farmsInputData,
                      depositAmount: e.target.value,
                    },
                  },
                )}
                placeholder="0.0"
                autoComplete="off"
                type="number"
                inputMode="decimal"
                onWheel={(e: any) => e.target!.blur()}
                error={insufficientBalance}
              />
              {insufficientBalance && (
                <styles.Error>
                  <Translate value="error.insufficientBalance" />
                </styles.Error>
              )}
            </div>
            <styles.ActionButton onClick={sendToken} disabled={disableButton}>
              <Translate value="action.depositReward" />
            </styles.ActionButton>
          </>
        )}
      </styles.Container>
      <styles.Time isDynamicEndDate={false}>
        {parseDate(startAt)}
        {'\xa0 \u2014 \xa0'}
        <span>{parseDate(endAt)}</span>
      </styles.Time>
    </styles.Wrapper>
  );
}

import { IConfig } from '../config';

export default {
  networkId: 'mainnet',
  nodeUrl: 'https://rpc.mainnet.near.org',
  walletUrl: 'https://wallet.near.org',
  helperUrl: 'https://api.kitwallet.app',
  explorerUrl: 'https://explorer.mainnet.near.org',
  farmContractId: 'farming-v1.jumbo_exchange.near',
  exchangeContractId: 'v1.jumbo_exchange.near',
  wNearAddress: 'wrap.near',
  walletId: 'near',
  usn: 'usn',
  myNearWalletUrl: 'https://app.mynearwallet.com/',
  indexerUrl: 'https://price-service.jumbo.exchange',
  jumboAddress: 'token.jumbo_exchange.near',
  stakingContractId: 'staking-v1.jumbo_exchange.near',
  stakingSeedId: 'token.jumbo_exchange.near',
} as IConfig;

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { EFromValues } from 'shared/interfaces';

import styles from './styles';

interface IMaterialDateTimePicker {
  name: EFromValues;
  value: string;
  setFieldValue?: (field: string, value: string, shouldValidate?: boolean | undefined) => void,
  error: boolean,
}

export default function MaterialDateTimePicker({
  name, value, setFieldValue, error,
}: IMaterialDateTimePicker) {
  const handleChange = (newValue: Date | null) => {
    if (!newValue) return;
    setFieldValue?.(name, newValue.toString());
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        value={value}
        onChange={handleChange}
        ampm={false}
        renderInput={(params) => (
          <TextField
            {...params}
            autoComplete="off"
            sx={styles.dateTimePickerStyles(error)}
          />
        )}
      />
    </LocalizationProvider>
  );
}

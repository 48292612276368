import { IPool } from 'providers/interfaces';
import { IPoolInfo } from 'services/interfaces';
import { ZERO } from 'shared/constant';

export default function formatPool(pool: IPoolInfo): IPool {
  return {
    id: pool.id,
    lpTokenId: `:${pool.id}`,
    type: pool.pool_kind,
    tokenAccountIds: pool.token_account_ids,
    amounts: pool.amounts,
    totalFee: pool.total_fee,
    sharesTotalSupply: pool.shares_total_supply,
    amp: pool.amp,
    farms: null,
    totalLiquidity: ZERO,
    supplies: pool.amounts.reduce(
      (acc: { [tokenId: string]: string }, amount: string, i: number) => {
        acc[pool.token_account_ids[i]] = amount;
        return acc;
      },
      {},
    ),
  };
}

import { t } from 'i18next';

import { useData } from 'providers/DataProvider';
import { useFromStakingProvider } from 'providers/LocalProviders/StakingProvider';
import { ITokenMetadata } from 'services/interfaces';
import { colors } from 'shared/theme';
import { displayAmount, parseDate } from 'shared/utils';

import styles from './styles';
import calculatedStakingStatistics from './utils';

export default function Statistics({ metadata }:{ metadata: ITokenMetadata }) {
  const { decimals, symbol } = metadata;
  const { staking } = useData();
  const { stakingInputs } = useFromStakingProvider();
  const updatedData = calculatedStakingStatistics(staking, stakingInputs, decimals);
  if (!updatedData) return null;
  const stakedInfo = [
    {
      title: t('staking.totalStaked'),
      value: t('token', {
        amount: displayAmount({
          amount: staking.totalStaked, decimals, precision: 0, isAmountWithSpace: true,
        }),
        symbol,
      }),
    },
    {
      title: t('staking.totalReward'),
      value: t('token', {
        amount: displayAmount({
          amount: updatedData.totalReward.value, decimals, precision: 0, isAmountWithSpace: true,
        }),
        symbol,
      }),
      color: updatedData.totalReward.isChanged ? colors.greenText : undefined,
    },
    {
      title: t('staking.apy.title'),
      value: updatedData.apy.isChanged
        ? t('staking.apy.newApy', { apy: staking.apy, newAPY: updatedData.apy.value })
        : t('staking.apy.value', { apy: staking.apy }),
      color: colors.greenText,
      fullWidth: true,
    },
    {
      title: t('staking.claimedReward'),
      value: t('token', {
        amount: displayAmount({
          amount: staking.claimedReward, decimals, precision: 0, isAmountWithSpace: true,
        }),
        symbol,
      }),
    },
    {
      title: t('staking.unclaimedReward'),
      value: t('token', {
        amount: displayAmount({
          amount: staking.unclaimedReward, decimals, precision: 0, isAmountWithSpace: true,
        }),
        symbol,
      }),
    },
    {
      title: t('staking.rewardPerSeconds'),
      value: t('token', { amount: displayAmount({ amount: updatedData.rewardPerSeconds.value, decimals }), symbol }),
      color: updatedData.totalReward.isChanged ? colors.greenText : undefined,
    },
  ];
  return (
    <styles.Wrapper>
      <styles.Row>
        {stakedInfo.slice(0, 3).map(({
          title, value, color, fullWidth,
        }) => (
          <div key={title}>
            <styles.ElementTitle>{title}</styles.ElementTitle>
            <styles.ElementValue color={color} fullWidth={fullWidth}>
              {value}
            </styles.ElementValue>
          </div>
        ))}
      </styles.Row>
      <styles.Time isDynamicEndDate={updatedData.endAt.isChanged}>
        {parseDate(staking.startAt)}
        {'\xa0 \u2014 \xa0'}
        <span>{parseDate(updatedData.endAt.value)}</span>
      </styles.Time>
      <styles.Row>
        {stakedInfo.slice(3).map(({ title, value, color }) => (
          <div key={title}>
            <styles.ElementTitle>{title}</styles.ElementTitle>
            <styles.ElementValue color={color}>{value}</styles.ElementValue>
          </div>
        ))}
      </styles.Row>
    </styles.Wrapper>
  );
}

export interface IFormValues {
  rewardToken: string,

  poolId: string,
  minDeposit: string,
  startAt: string,
  sessionInterval: string,
  distributionInDay: string,
  distributionAmount: string,

  farmIndex: string,
}

export enum EFromValues {
  rewardToken = 'rewardToken',

  poolId = 'poolId',
  minDeposit = 'minDeposit',
  startAt = 'startAt',

  sessionInterval = 'sessionInterval',
  distributionInDay = 'distributionInDay',
  distributionAmount = 'distributionAmount',

  farmIndex = 'farmIndex',
}

export enum ETypeInput {
  TEXT = 'text',
  NUMERICAL = 'number',
  DATE_TIME = 'dateTime',
}

export interface IFormattedValues {
  rewardToken: string,

  poolId: string,
  seedId: string,
  rewardPerSession: string,
  sessionInterval: number,
  minDeposit?: string,
  startAt: number,

  amount: string,

  message: string,
  mftTokenId: string,
}

export interface IFormattedStakingValues {
  seedId: string;
  rewardToken: string;
  rewardPerSession: string;
  sessionInterval: number;
  startAt: number;
  minDeposit?: string;
}

export interface IFormStakingValues {
  minDeposit: string,
  startAt: string,
  sessionInterval: string,
  distributionInDay: string,
  distributionAmount: string,
}

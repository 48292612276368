import { useState } from 'react';

import { useData } from 'providers/DataProvider';
import { getToken } from 'providers/helpers';
import { useWalletData } from 'providers/WalletSelectorProvider';
import FungibleTokenContract from 'services/contract/FungibleToken';

const useMetadataToken = () => {
  const { RPCProvider } = useWalletData();
  const { tokens } = useData();

  const [token, setToken] = useState<FungibleTokenContract | null>(null);
  const [isLoadingTokenFailed, setIsLoadingTokenFailed] = useState<boolean>(false);

  const getMetadataToken = async (tokenId: string) => {
    const uploadedToken = getToken(tokenId, tokens);
    if (uploadedToken) {
      setToken(uploadedToken);
      setIsLoadingTokenFailed(false);
      return;
    }
    try {
      const ftTokenContract: FungibleTokenContract = new FungibleTokenContract(RPCProvider, tokenId);
      const tokenMetadata = await ftTokenContract.getMetadata();
      setToken(ftTokenContract);

      const isLoadingSuccessfully = Boolean(tokenMetadata);
      setIsLoadingTokenFailed(!isLoadingSuccessfully);
    } catch (e){
      setIsLoadingTokenFailed(false);
      console.warn(`Error: ${e} while getMetadataToken`);
    }
  };

  return ({
    token, getMetadataToken, isLoadingTokenFailed, setIsLoadingTokenFailed,
  });
};

export default useMetadataToken;

import { Form } from 'formik';
import styled from 'styled-components';

import Buttons from 'shared/components/Buttons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const From = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
`;

const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Button = styled(Buttons.Primary)`
  align-self: center;
  margin-top: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`width: 100%;`}
`;

export default {
  Container,
  From,
  FormContainer,
  Button,
};

import { IFormattedStaking } from 'providers/interfaces';
import { EFarmStatus } from 'services/interfaces';

export const convertStakingStatusEnumToNumber = (stage: EFarmStatus) => {
  switch (stage) {
    case EFarmStatus.Created:
      return 0;
    case EFarmStatus.Running:
      return 1;
    case EFarmStatus.Ended:
      return 2;
    default:
      return 5;
  }
};

export const sortingStaking = (staking: IFormattedStaking[]) => staking.sort(
  (a, b) => convertStakingStatusEnumToNumber(a.status) - convertStakingStatusEnumToNumber(b.status),
);

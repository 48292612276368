import { PoolContract } from 'services/contract';
import { IPoolInfo } from 'services/interfaces';
import { DEFAULT_PAGE_LIMIT } from 'shared/constant';
import { isNotNullOrUndefined, formatPool } from 'shared/utils';

import { assertFulfilled } from './index';

export async function retrievePoolResult(pages: number, contract: PoolContract) {
  return (await Promise.allSettled(
    [...Array(pages)]
      .map((_, i) => contract.getPools(i * DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_LIMIT)),
  )).filter(assertFulfilled)
    .map(({ value }) => value)
    .flat();
}
export async function retrievePoolArray(contract: PoolContract) {
  const poolsLength = await contract.getNumberOfPools();
  const pages = Math.ceil(poolsLength || DEFAULT_PAGE_LIMIT);
  const poolsResult = await retrievePoolResult(pages, contract);
  const poolArray = poolsResult
    .filter(isNotNullOrUndefined)
    .map((pool: IPoolInfo) => formatPool(pool));
  return poolArray;
}

import styled from 'styled-components';

import SpecialContainer from 'shared/components/SpecialContainer';

const Wrapper = styled(SpecialContainer)`
  width: 100%;
  border-radius: 1.5rem;
  margin: 0;
  padding: 1rem;
  min-height: 160px;
  ::before{
    border-radius: 1.5rem;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1rem;
  & > div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    :first-child {
      align-items: flex-start;
    }
    :nth-child(2) {
      align-items: flex-end;
    }
    :last-child {
      align-items: center;
      grid-area: 2 / 1 / 3 / 3;
    }
  }
`;

const ElementTitle = styled.div`
  display: flex;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: ${({ theme }) => theme.grey};
`;

const ElementValue = styled.div<{ color?: string, isButton?: boolean, fullWidth?: boolean }>`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.188rem;
  color: ${({ theme, color }) => color || theme.white};
  cursor: ${({ isButton }) => (isButton ? 'pointer' : 'default')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${({ fullWidth }) => (fullWidth ? '20.563' : '10rem')};
`;

const Time = styled.div<{ isDynamicEndDate?: boolean }>`
  font-style: normal;
  font-weight: 300;
  font-size: .75rem;
  line-height: .875rem;
  text-align: center;
  margin: 2rem 0;
  span {
    color: ${({ isDynamicEndDate, theme }) => (isDynamicEndDate ? theme.greenText : theme.white)}
  }
`;

export default {
  Wrapper,
  Row,
  ElementTitle,
  ElementValue,
  Time,
};

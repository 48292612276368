import { useWalletData } from 'providers/WalletSelectorProvider';
import { APP_ROUTES } from 'routes';
import {
  Link, LinkProps, useMatch, useResolvedPath,
} from 'services/router';
import { colors } from 'shared/theme';

import Translate from '../Translate';
import styles from './styles';

function CustomLink({
  children, to,
}: LinkProps) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link to={to}>
      <styles.NavButton
        isActive={Boolean(match)}
      >
        {children}
      </styles.NavButton>
    </Link>
  );
}

export default function Header() {
  const {
    openModal, isSignedIn, accountId, signOut,
  } = useWalletData();

  const buttonAction = () => (
    isSignedIn
      ? signOut()
      : openModal()
  );

  const title = isSignedIn && accountId
    ? accountId
    : <Translate value="action.connectWallet" />;

  return (
    <styles.Header>
      <styles.LogoContainer>
        <styles.JumboLogo />
        <styles.LogoTitle>jumbo</styles.LogoTitle>
      </styles.LogoContainer>
      {isSignedIn && (
        <styles.NavBar>
          <CustomLink to={APP_ROUTES.CREATE_FARM}>
            <Translate value="header.createFarm" />
          </CustomLink>
          <CustomLink to={APP_ROUTES.FT_AND_MFT}>
            <Translate value="header.rewardToken" />
          </CustomLink>
          <CustomLink to={APP_ROUTES.STAKING}>
            <Translate value="header.staking" />
          </CustomLink>
        </styles.NavBar>
      )}
      <styles.Button onClick={buttonAction} bg={isSignedIn ? colors.darkPink : colors.pink}>
        <styles.WalletLogo />
        {title}
      </styles.Button>
    </styles.Header>
  );
}

import styled from 'styled-components';

const Primary = styled.button`
  max-height: 54px;
  outline: none;
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12.5px 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 19px;
  min-height: 50px;
  border: 2px solid ${({ theme }) => theme.pink};
  background: ${({ theme }) => theme.pink};
  color: ${({ theme }) => theme.white};
  border-radius: 12px;
  :hover {
    cursor: pointer;
    border: 2px solid ${({ theme }) => theme.pinkHover};
    background: ${({ theme }) => theme.pinkHover};
  }
  :active {
    border: 2px solid ${({ theme }) => theme.pinkActive};
    background: ${({ theme }) => theme.pinkActive};
  }
  :disabled{
    cursor: default;
    background: ${({ theme }) => theme.greyOp01};
    border: 1px solid ${({ theme }) => theme.greyOp01};
    color: ${({ theme }) => theme.grey};
  }
`;

const Secondary = styled(Primary)`
  background: transparent;
  border-color: ${({ theme }) => theme.darkPink};
  :hover {
    cursor: pointer;
    background: transparent;
    border-color: ${({ theme }) => theme.pinkHover};
  }
  :active {
    background: transparent;
    border-color: ${({ theme }) => theme.pinkActive};
  }
  :disabled{
    cursor: default;
    background: transparent;
    border: 1px solid ${({ theme }) => theme.greyOp01};
    color: ${({ theme }) => theme.grey};
  }
`;

export default {
  Primary,
  Secondary,
};

export const PROPERTY_NAME = 'FunctionCall';
export const TRANSACTION_HASHES = 'transactionHashes';
export const ERROR_CODE = 'errorCode';
export const ERROR_MESSAGE = 'errorMessage';

interface IContractMethodsName {
  transferRewardToken: string,
  createSimpleFarm: string,
  confirm: string,
}

export const contractMethodsName: IContractMethodsName = {
  transferRewardToken: 'ft_transfer_call',
  createSimpleFarm: 'create_simple_farm',
  confirm: 'confirm',
};

export enum TransactionType {
  None = 0,
  TransferRewardToken,
  CreateSimpleFarm,
}

export enum StatusType {
  None,
  SuccessValue,
  Failure,
}

import pageStyles from 'pages/styles';
import { useFromStakingProvider } from 'providers/LocalProviders/StakingProvider';
import formStyles from 'shared/components/FormComponent/TermsForm/styles';
import Input from 'shared/components/Input';
import Translate from 'shared/components/Translate';
import { ETypeInput, EFromValues } from 'shared/interfaces';

export default function CreateStaking() {
  const { createStakingForm } = useFromStakingProvider();
  const {
    getFieldMeta, getFieldProps, setFieldValue, submitForm,
  } = createStakingForm;
  return (
    <formStyles.Container>
      <formStyles.Title>
        <formStyles.JumboIcon />
        <Translate value="title.createStaking" />
      </formStyles.Title>
      <Input
        type={ETypeInput.NUMERICAL}
        name={EFromValues.sessionInterval}
        field={getFieldProps(EFromValues.sessionInterval)}
        meta={getFieldMeta(EFromValues.sessionInterval)}
      />
      <Input
        type={ETypeInput.NUMERICAL}
        name={EFromValues.distributionAmount}
        field={getFieldProps(EFromValues.distributionAmount)}
        meta={getFieldMeta(EFromValues.distributionAmount)}
      />
      <Input
        type={ETypeInput.NUMERICAL}
        name={EFromValues.distributionInDay}
        field={getFieldProps(EFromValues.distributionInDay)}
        meta={getFieldMeta(EFromValues.distributionInDay)}
      />
      <Input
        type={ETypeInput.DATE_TIME}
        name={EFromValues.startAt}
        field={getFieldProps(EFromValues.startAt)}
        meta={getFieldMeta(EFromValues.startAt)}
        setFieldValue={setFieldValue}
      />
      <Input
        type={ETypeInput.NUMERICAL}
        name={EFromValues.minDeposit}
        field={getFieldProps(EFromValues.minDeposit)}
        meta={getFieldMeta(EFromValues.minDeposit)}
      />
      <formStyles.Description>
        <span>
          <Translate value="description.note" />
        </span>
        <br />
        <Translate value="description.termsForm1" />
        <br />
        <Translate value="description.termsForm2" />
      </formStyles.Description>
      <pageStyles.Button
        onClick={submitForm}
      >
        <Translate value="action.createStaking" />
      </pageStyles.Button>
    </formStyles.Container>
  );
}

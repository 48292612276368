import dayjs from 'dayjs';
import * as Yup from 'yup';

import { usn } from 'services/config';
import i18n from 'services/translation';
import { VALID_VALUE_CONTRACT_ID } from 'shared/constant';

export const validationCreateFarmSchema = Yup.object({
  rewardToken: Yup.string()
    .trim()
    .test(
      'Is valid token id?',
      i18n.t('error.rewardToken', { walletId: VALID_VALUE_CONTRACT_ID }),
      (value) => {
        if (value && value.toLowerCase() === usn) return true;
        if ((value && value.includes(VALID_VALUE_CONTRACT_ID))) return true;
        return false;
      },
    )
    .required(i18n.t('error.required')),

  poolId: Yup.number()
    .test(
      'Is positive?',
      i18n.t('error.mustBePositiveValue'),
      (value) => (value || 0) >= 0,
    )
    .required(i18n.t('error.required')),
  sessionInterval: Yup.number()
    .test(
      'Is positive?',
      i18n.t('error.mustBePositiveValue'),
      (value) => (value || 0) >= 0,
    )
    .required(i18n.t('error.required')),
  distributionInDay: Yup.number()
    .positive(i18n.t('error.mustBePositiveValue'))
    .required(i18n.t('error.required')),
  distributionAmount: Yup.number()
    .positive(i18n.t('error.mustBePositiveValue'))
    .required(i18n.t('error.required')),

  startAt: Yup.string()
    .test(
      'Is valid date?',
      i18n.t('error.startDate'),
      (value) => {
        const newDate = dayjs(value).add(dayjs().utcOffset(), 'minutes').valueOf();
        if (newDate > new Date().valueOf()) return true;
        return false;
      },
    )
    .required(i18n.t('error.required')),
  minDeposit: Yup.number()
    .test(
      'Is positive?',
      i18n.t('error.mustBePositiveValue'),
      (value) => (value || 0) >= 0,
    )
    .notRequired(),
});

export const validationTransferTokenSchema = Yup.object({
  distributionAmount: Yup.number()
    .positive(i18n.t('error.mustBePositiveValue'))
    .required(i18n.t('error.required')),
  farmIndex: Yup.number()
    .test(
      'Is positive?',
      i18n.t('error.mustBePositiveValue'),
      (value) => (value || 0) >= 0,
    )
    .required(i18n.t('error.required')),
  poolId: Yup.number()
    .test(
      'Is positive?',
      i18n.t('error.mustBePositiveValue'),
      (value) => (value || 0) >= 0,
    )
    .required(i18n.t('error.required')),
});

export const validationCreateStakingSchema = Yup.object({
  sessionInterval: Yup.number()
    .test(
      'Is positive?',
      i18n.t('error.mustBePositiveValue'),
      (value) => (value || 0) >= 0,
    )
    .required(i18n.t('error.required')),
  distributionInDay: Yup.number()
    .positive(i18n.t('error.mustBePositiveValue'))
    .required(i18n.t('error.required')),
  distributionAmount: Yup.number()
    .positive(i18n.t('error.mustBePositiveValue'))
    .required(i18n.t('error.required')),

  startAt: Yup.string()
    .test(
      'Is valid date?',
      i18n.t('error.startDate'),
      (value) => {
        const newDate = dayjs(value).add(dayjs().utcOffset(), 'minutes').valueOf();
        if (newDate > new Date().valueOf()) return true;
        return false;
      },
    )
    .required(i18n.t('error.required')),
  minDeposit: Yup.number()
    .test(
      'Is positive?',
      i18n.t('error.mustBePositiveValue'),
      (value) => (value || 0) >= 0,
    )
    .notRequired(),
});

import Big from 'big.js';

import { SECONDS_IN_A_DAY, ZERO } from 'shared/constant';

Big.RM = Big.roundDown;
Big.DP = 30;

const calcRewardPerSession = (
  distributionInDay: string,
  distributionAmount: string,
  sessionInterval: string,
) => {
  try {
    const denominator = Big(distributionInDay).mul(SECONDS_IN_A_DAY);
    const rewardPerSecond = Big(distributionAmount).div(denominator);
    const rewardPerSession = rewardPerSecond.mul(sessionInterval).toFixed();
    return { rewardPerSession };
  } catch (e) {
    return { rewardPerSession: ZERO };
  }
};

export default calcRewardPerSession;

import Big from 'big.js';
import { providers } from 'near-api-js';
import { useEffect } from 'react';

import { explorerUrl, nodeUrl } from 'services/config';
import ToastService from 'shared/components/Toast';
import { FARM_ID_KEY } from 'shared/constant';
import { toArray } from 'shared/utils';

import {
  TransactionType,
  StatusType,
  contractMethodsName,
  PROPERTY_NAME,
  TRANSACTION_HASHES,
  ERROR_CODE,
  ERROR_MESSAGE,
} from './constants';

const getTransaction = (transactions: providers.FinalExecutionOutcome[]) => {
  const [transaction] = transactions.filter((tx) => toArray(contractMethodsName)
    .indexOf(tx.transaction.actions[0][PROPERTY_NAME].method_name) !== -1);

  switch (transaction.transaction.actions[0][PROPERTY_NAME].method_name) {
    case contractMethodsName.transferRewardToken: {
      return { type: TransactionType.TransferRewardToken, transaction };
    }
    case contractMethodsName.createSimpleFarm: {
      return { type: TransactionType.CreateSimpleFarm, transaction };
    }
    default: {
      return { type: TransactionType.None, transaction };
    }
  }
};

const detailsTransaction = (transaction: providers.FinalExecutionOutcome, type: TransactionType) => {
  const { hash } = transaction.transaction;

  const successStatus = Object.prototype.hasOwnProperty.call(transaction.status, 'SuccessValue');
  if (type === TransactionType.TransferRewardToken) {
    const successValue = (transaction.status as providers.FinalExecutionStatus).SuccessValue || '';
    const buff = Buffer.from(successValue, 'base64');
    const value = buff.toString('ascii');
    const depositStatus = Big(value.replace(/"/g, '') || 0).gt(0);

    return {
      hash,
      status: depositStatus && successStatus ? StatusType.SuccessValue : StatusType.Failure,
    };
  }
  if (type === TransactionType.CreateSimpleFarm) {
    const successValue = (transaction.status as providers.FinalExecutionStatus).SuccessValue || '';
    const buff = Buffer.from(successValue, 'base64');
    const value = buff.toString('ascii');
    const farmId = value.replace(/"/g, '');
    localStorage.setItem(FARM_ID_KEY, farmId);
    return {
      hash,
      status: successStatus ? StatusType.SuccessValue : StatusType.Failure,
    };
  }
  return {
    hash,
    status: successStatus ? StatusType.SuccessValue : StatusType.Failure,
  };
};

const analyzeTransactions = (transactions: providers.FinalExecutionOutcome[]): {
  type: TransactionType,
  status: StatusType,
  hash: string
} => {
  const { type, transaction } = getTransaction(transactions);
  if (!transaction || type === TransactionType.None) {
    return {
      type,
      status: StatusType.None,
      hash: '',
    };
  }
  const { hash, status } = detailsTransaction(transaction, type);
  return {
    type,
    status,
    hash,
  };
};

export const parseTransactions = (txs: providers.FinalExecutionOutcome[]) => {
  const { type, status, hash } = analyzeTransactions(txs);
  const href = `${explorerUrl}/transactions/${hash}`;

  switch (type) {
    case TransactionType.CreateSimpleFarm:
      if (status === StatusType.SuccessValue) {
        ToastService.success('toast.createFarm', href);
      } else if (status === StatusType.Failure) {
        ToastService.error('toast.createFarm', href);
      }
      break;
    case TransactionType.TransferRewardToken:
      if (status === StatusType.SuccessValue) {
        ToastService.success('toast.transferRewardToken', href);
      } else if (status === StatusType.Failure) {
        ToastService.error('toast.transferRewardToken', href);
      }
      break;
    default: {
      break;
    }
  }
};

const clearHash = (queryParams: URLSearchParams) => {
  const url = new URL(window.location.href);
  if (queryParams.has(TRANSACTION_HASHES)) queryParams.delete(TRANSACTION_HASHES);

  if (queryParams.has(ERROR_CODE) || queryParams.has(ERROR_MESSAGE)) {
    queryParams.delete(ERROR_CODE);
    queryParams.delete(ERROR_MESSAGE);
  }
  window.history.replaceState({}, document.title, url.pathname);
};

export default function useTransactionHash(
  query: string | undefined,
  accountId: string,
) {
  return useEffect(() => {
    if (accountId) {
      const queryParams = new URLSearchParams(query);
      const transactions = queryParams?.get(TRANSACTION_HASHES);
      const errorCode = queryParams?.get(ERROR_CODE);
      const errorMessage = queryParams?.get(ERROR_MESSAGE);

      if (errorCode || errorMessage) {
        ToastService.error('toast.userRejected');
        clearHash(queryParams);
        return;
      }

      if (transactions) {
        const provider = new providers.JsonRpcProvider(nodeUrl);
        try {
          Promise.all(transactions.split(',').map(
            (txHash) => provider.txStatus(txHash, accountId),
          )).then(
            (res) => parseTransactions(res),
          );
        } catch (e) {
          console.warn(`${e} error while loading tx`);
        }
      }
      clearHash(queryParams);
    }
  }, [query, accountId]);
}

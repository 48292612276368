import { assertFulfilled } from 'providers/helpers';
import { IPool } from 'providers/interfaces';
import { FarmContract } from 'services/contract';
import { IFarmInfo } from 'services/interfaces';
import { DEFAULT_PAGE_LIMIT } from 'shared/constant';
import { getSeedsAmount, isNotNullOrUndefined, formatFarm } from 'shared/utils';

export async function retrieveFarmResult(pages: number, contract: FarmContract) {
  return (await Promise.allSettled(
    [...Array(pages)]
      .map((_, i) => contract.getListFarms(i * DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_LIMIT)),
  )).filter(assertFulfilled)
    .map(({ value }) => value)
    .flat();
}

export async function retrieveFarmArray(
  farmContract: FarmContract,
  poolArray: IPool[],
) {
  const farmsLength = await farmContract.getNumberOfFarms();
  const pages = Math.ceil(farmsLength || DEFAULT_PAGE_LIMIT);
  const farmsResult = await retrieveFarmResult(pages, farmContract);
  const seedsAmount = getSeedsAmount(farmsResult);
  const seeds = await farmContract.getSeeds(0, seedsAmount);
  const farmArray = farmsResult
    .filter(isNotNullOrUndefined)
    .map((farm: IFarmInfo) => formatFarm(farm, poolArray, seeds))
    .filter(isNotNullOrUndefined);
  return farmArray;
}

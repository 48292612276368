import Big from 'big.js';

import { ITokenPrice, IPool } from 'providers/interfaces';
import { wNearAddress } from 'services/config';
import { ZERO } from 'shared/constant';

import { formatTokenAmount, removeTrailingZeros } from './formatAmount';
import { toMap } from './index';

const calculateTotalAmount = (
  prices: { [key: string]: ITokenPrice },
  pools: Array<IPool>,
):{ [key: string]: IPool } => {
  const calculatedPools = pools.map((pool: IPool) => {
    const [firstTokenId, secondTokenId] = pool.tokenAccountIds;
    const firstPriceData = prices[firstTokenId];
    const secondPriceData = prices[secondTokenId];
    if (firstPriceData && secondPriceData) {
      const firstAmount = formatTokenAmount(pool.supplies[firstPriceData.id], firstPriceData.decimal);
      const secondAmount = formatTokenAmount(pool.supplies[secondPriceData.id], secondPriceData.decimal);

      let firstPrice = firstPriceData.price;
      let secondPrice = secondPriceData.price;
      if (firstTokenId === wNearAddress || secondTokenId === wNearAddress) {
        if (firstTokenId === wNearAddress) {
          secondPrice = Big(secondAmount).gt(0) ? (Big(firstAmount)
            .mul(firstPrice).div(secondAmount).toFixed(4)) : ZERO;
        } else {
          firstPrice = Big(firstAmount).gt(0) ? (Big(secondAmount)
            .mul(secondPrice).div(firstAmount).toFixed(4)) : ZERO;
        }
      }

      const firstLiquidity = new Big(firstAmount).mul(firstPrice);
      const secondLiquidity = new Big(secondAmount).mul(secondPrice);
      const totalLiquidityAmount = new Big(firstLiquidity).add(secondLiquidity);
      const totalLiquidityValue = removeTrailingZeros(totalLiquidityAmount.toFixed(4));
      return {
        ...pool,
        totalLiquidity: totalLiquidityValue,
      };
    }
    return {
      ...pool,
    };
  });

  return toMap(calculatedPools, 'id');
};

export default calculateTotalAmount;

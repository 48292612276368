import { useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';

import { useData } from 'providers/DataProvider';
import { FungibleTokenContract } from 'services/contract';
import AutocompleteInput from 'shared/components/Input/Autocomplete';
import Translate from 'shared/components/Translate';
import { ETypeInput, IFormValues, EFromValues } from 'shared/interfaces';

import styles from './styles';

interface IPrimaryForm {
  rewardToken: FungibleTokenContract | null
  getMetadataToken: (tokenId: string) => Promise<void>,
  isLoadingTokenFailed: boolean,
}

export default function PrimaryForm({
  rewardToken,
  getMetadataToken,
  isLoadingTokenFailed,
}: IPrimaryForm) {
  const { values, setFieldValue } = useFormikContext<IFormValues>();
  const { tokens } = useData();
  const metadata = rewardToken?.metadata;

  const handleClick = useCallback(() => {
    getMetadataToken(values.rewardToken);
  }, [getMetadataToken, values.rewardToken]);

  const tokensList = useMemo(() => Object.values(tokens).map((token) => ({
    title: token.metadata ? token.metadata.symbol : token.contractId,
    value: token.contractId,
  })), [tokens]);

  const titleButton = useMemo(() => (metadata
    ? (
      <styles.TitleMetadata>
        symbol:
        {' '}
        {metadata.symbol}
        , decimals:
        {' '}
        {metadata.decimals}
        , icon:
        {' '}
        <img src={metadata.icon} alt={metadata.symbol} />
      </styles.TitleMetadata>
    )
    : <Translate value="action.getMetadata" />), [metadata]);

  useEffect(() => {
    if (rewardToken?.metadata && rewardToken?.contractId !== values.rewardToken){
      getMetadataToken('');
    }
  }, [values.rewardToken]);

  return (
    <styles.Container>
      <styles.Title>
        <styles.JumboIcon />
        <Translate value="title.primaryInfo" />
      </styles.Title>
      <AutocompleteInput
        name={EFromValues.rewardToken}
        value={values.rewardToken}
        type={ETypeInput.TEXT}
        optionList={tokensList}
        setFieldValue={setFieldValue}
      />
      <styles.Button
        onClick={handleClick}
        isSuccess={!!metadata}
        type="button"
      >
        {titleButton}
      </styles.Button>
      {isLoadingTokenFailed && (
      <styles.Error>
        <Translate value="error.metadata" />
      </styles.Error>
      ) }
    </styles.Container>
  );
}

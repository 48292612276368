import { Action, EEnvironmentKind } from 'services/interfaces';

export const PoolViewMethods = {
  getPools: 'get_pools',
  getNumberOfPools: 'get_number_of_pools',
  getPool: 'get_pool',
};

export enum PoolChangeMethods {
  registerMft = 'mft_register',
}

export interface IPoolContract {
  getNumberOfPools: (from: number, limit: number) => Promise<number | undefined>,
  getPools: (from: number, limit: number) => Promise<IPoolInfo[] | undefined>,
  getPool: (poolId: number) => Promise<IPoolInfo | undefined>,
  registerTokens: (mftId: string, farmContractId: string) => Action[]
}

export interface IPoolInfo {
  id: number,
  pool_kind: EEnvironmentKind,
  token_account_ids: string[],
  amounts: string[],
  total_fee: number,
  shares_total_supply: string,
  amp: number,
}

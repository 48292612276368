import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: sticky;
  height: fit-content;
  top: 1rem;
  flex: 1;
`;

export default {
  Container,
};

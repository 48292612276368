import styled from 'styled-components';

import { ReactComponent as InfoIcon } from 'assets/images/image-icon/info-icon.svg';
import { colors } from 'shared/theme';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: flex-start;
  margin: 1rem 0;
`;

const Title = styled.div`
  margin-left: 1rem;
  margin-bottom: .5rem;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.313rem;
  color: ${({ theme }) => theme.grey};
  & > svg {
    margin-left: .5rem;
    :hover {
      cursor: pointer;
    }
  }
`;

const InputWrapper = styled.div<{ error?: boolean }>`
  height: 50px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({ error }) => (error ? '.688rem' : '.75rem')};

  border: ${({ error, theme }) => (error
    ? `2px solid ${theme.error}`
    : `1px solid ${theme.greyOp04}`)};

  border-radius: 12px;

  :focus-within {
    border: 2px solid ${({ theme }) => theme.pink};
    padding: .688rem;
  }

  .MuiInput-underline {
    ::before, ::after {
      display: none;
    }
    input {
      padding: 0!important;
      font-size: 1.25rem;
      font-weight: 300;
      height: auto;
      ::placeholder {
        opacity: 1;
        color: ${({ theme }) => theme.greyOp04};
      }
    }
  }
`;

const Error = styled.div`
  position: absolute;
  bottom: -20px;
  color: ${({ theme }) => theme.error};
  left: 1rem;
  font-weight: 300;
  font-size: .75rem;
  line-height: .875rem;
`;

const StyledInput = styled.input`
  font-size: 1.25rem;
  outline: none;
  border: none;
  flex: 1 1 auto;
  color: ${({ theme }) => theme.white};
  background-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
  width: 100%;
  padding: 0;

  ::placeholder {
    color: ${({ theme }) => theme.greyOp04};
  }
`;

const Datalist = styled.div`
  position: absolute;
  top: 60px;
  background-color: ${({ theme }) => theme.greyOp02};
  color: white;
  border-radius: 12px;
  padding: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: .75rem;
  line-height: 1.063rem;
  min-width: 300px;
  max-width: 600px;
  max-height: 200px;
  overflow: scroll;
  z-index: 100;
`;

const Option = styled.div`
  font-size: 1rem;
  margin-bottom: .5rem;
  padding: 2px;
  color: ${({ theme }) => theme.white};
  & > span:first-child {
    color: ${({ theme }) => theme.grey};
  }
  & > span:last-child {
    margin: 0 .25rem;
    color: ${({ theme }) => theme.white};
  }
  :hover {
    cursor: pointer;
    border-radius: 8px;
    color: ${({ theme }) => theme.grey};
    text-decoration: underline;
    & > span:first-child {
      color: ${({ theme }) => theme.white};
    }
  }
`;

const dateTimePickerStyles = (error: boolean) => ({
  width: '100%',
  '& .css-q2lp9a-MuiInputBase-root-MuiOutlinedInput-root.Mui-error': {
    '& fieldset': {
      borderRadius: '12px',
      border: error
        ? `2px solid ${colors.error}`
        : `1px solid ${colors.greyOp04}`,
    },
  },
  '& .MuiOutlinedInput-root': {
    '& input': {
      fontWeight: 300,
      padding: '.75rem',
      fontSize: '1.25rem',
      '::placeholder': {
        color: colors.greyOp04,
      },
    },
    '& fieldset': {
      borderRadius: '12px',
      border: error
        ? `2px solid ${colors.error}`
        : `1px solid ${colors.greyOp04}`,
    },
  },
});

export default {
  Container,
  Title,
  InfoIcon,
  InputWrapper,
  Error,
  StyledInput,
  Datalist,
  Option,
  dateTimePickerStyles,
};

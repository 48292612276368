import {
  IPool, IFarm, ITokenPrice, IBalance,
} from 'providers/interfaces';
import { ApiService } from 'services/api';
import { wNearAddress, helperUrl, stakingSeedId } from 'services/config';
import { FungibleTokenContract } from 'services/contract';
import { IRPCProviderService } from 'services/RPCProviderService';
import { HEADERS, RequestTypes, ZERO } from 'shared/constant';
import { isNotNullOrUndefined, toMap } from 'shared/utils';

export function retrieveTokenAddresses(pools: IPool[], farms: IFarm[]): string[] {
  return Array.from(
    new Set(
      [...pools
        .flatMap((pool) => pool.tokenAccountIds),
      ...farms
        .flatMap((farm) => farm.rewardTokenId),
      wNearAddress,
      stakingSeedId,
      ],
    ),
  );
}

export async function retrieveFilteredTokenMetadata(
  RPCProvider: IRPCProviderService,
  tokenAddresses: string[],
): Promise<FungibleTokenContract[]> {
  return Promise.all(tokenAddresses.map(async (address: string) => {
    const ftTokenContract: FungibleTokenContract = new FungibleTokenContract(RPCProvider, address);
    await ftTokenContract.getMetadata();
    return ftTokenContract;
  }));
}

export async function getUserWalletTokens(accountId: string) {
  const url = `${helperUrl}/account/${accountId}/likelyTokens`;
  try {
    if (!accountId) return [];
    return await fetch(
      url,
      {
        method: RequestTypes.GET,
        headers: HEADERS,
      },
    )
      .then((res) => res.json())
      .then((tokens) => tokens);
  } catch (e) {
    console.warn(`Error ${e} while loading likely tokens from ${url}`);
    return [];
  }
}

export async function getPrices(): Promise<{ [key: string]: ITokenPrice }> {
  const [allPrices, nearPrice] = await Promise.all([
    ApiService.getPriceData(),
    ApiService.getNearPrice(),
  ]);
  if (nearPrice) {
    return {
      ...allPrices,
      [wNearAddress]: { ...allPrices[wNearAddress], price: nearPrice, symbol: 'WNEAR' },
    };
  }
  return allPrices;
}

export async function retrieveBalances(
  tokens: Array<FungibleTokenContract>,
  accountId: string,
): Promise<{ [key: string]: IBalance }> {
  const balancesArray: Array<IBalance | null> = await Promise.all(
    tokens.map(async (tokenContract) => {
      if (!tokenContract.metadata) return null;
      const balance: string = (await tokenContract.getBalanceOf({ accountId })) || ZERO;
      return {
        address: tokenContract.contractId,
        balance,
        decimal: tokenContract.metadata.decimals,
        symbol: tokenContract.metadata.symbol,
      };
    }),
  );
  const filteredBalancesArray = balancesArray.filter(isNotNullOrUndefined);

  return toMap(filteredBalancesArray, 'address');
}

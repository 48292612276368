export * from './ftContract';
export * from './poolContract';
export * from './farmContract';
export * from './staking';

export interface Action {
  receiverId: string;
  functionCalls: {
    gas?: string;
    amount?: string;
    methodName: string;
    args?: {
      registration_only?: boolean,
      account_id?: string,
      receiver_id?: string,
      amount?: string,
      msg?: string,

      terms?: {
        start_at?: number,
        seed_id?: string,
        reward_token?: string,
        reward_per_session?: string,
        session_interval?: number,
      },
      min_deposit?: string,
      token_id?: string,
    };
  }[];
}

export enum EEnvironmentKind {
  SIMPLE_POOL = 'SIMPLE_POOL',
  STABLE_SWAP = 'STABLE_SWAP',
}

export interface IArgsViewFunction {
  pool_id?: number,
  account_id?: string,
  from_index?: number,
  limit?: number,
  farm_id?: string,
  seed_id?: string,
}

import { retrieveFarmArray } from 'providers/helpers/farms';
import { retrievePoolArray } from 'providers/helpers/pools';
import retrieveStaking from 'providers/helpers/staking';
import {
  getUserWalletTokens, getPrices, retrieveTokenAddresses, retrieveFilteredTokenMetadata, retrieveBalances,
} from 'providers/helpers/tokens';
import { ITokenPrice } from 'providers/interfaces';
import { PoolContract, FarmContract, FungibleTokenContract } from 'services/contract';
import StakingContract from 'services/contract/StakingContract';
import { IRPCProviderService } from 'services/RPCProviderService';
import {
  isNotNullOrUndefined,
  toMap,
} from 'shared/utils';
import calculateTotalAmount from 'shared/utils/calculateTotalAmount';

export function assertFulfilled<T>(item: PromiseSettledResult<T>): item is PromiseFulfilledResult<T> {
  return item.status === 'fulfilled';
}

export async function retrieveInitialData(
  poolContract: PoolContract,
  farmContract: FarmContract,
  stakingContract: StakingContract,
  RPCProvider: IRPCProviderService,
  accountId: string,
) {
  const poolArray = await retrievePoolArray(poolContract);
  const [
    farmArray,
    userWalletTokens,
    prices,
    stakingData,
  ] = await Promise.all(
    [
      retrieveFarmArray(farmContract, poolArray),
      getUserWalletTokens(accountId),
      getPrices(),
      retrieveStaking(stakingContract),
    ],
  );
  const tokenAddresses = retrieveTokenAddresses(poolArray, farmArray);
  if (stakingData) tokenAddresses.push(stakingData.rewardTokenId);
  const uniqueAddresses = Array.from(new Set([...tokenAddresses, ...userWalletTokens]));

  const tokensMetadataFiltered = await retrieveFilteredTokenMetadata(RPCProvider, uniqueAddresses);
  const balances = await retrieveBalances(tokensMetadataFiltered, accountId);
  const tokenMetadataMap = tokensMetadataFiltered.reduce((acc, curr) => ({ ...acc, [curr.contractId]: curr }), {});
  const newPoolArray = poolArray.filter(isNotNullOrUndefined);
  const newFarmArray = farmArray.filter(isNotNullOrUndefined);

  const poolsMap = calculateTotalAmount(prices, newPoolArray);
  const farmsMap = toMap(newFarmArray, 'id');
  return {
    poolsMap,
    farmsMap,
    tokenMetadataMap,
    pricesMap: prices,
    balanceMap: balances,
    stakingData,
  };
}

export const getToken = (
  tokenId: string,
  tokens: { [key: string]: FungibleTokenContract },
): FungibleTokenContract | null => (tokenId ? tokens[tokenId] ?? null : null);

export const getPriceData = (
  tokenId: string,
  prices: { [key: string]: ITokenPrice },
): ITokenPrice | null => (tokenId ? prices[tokenId] ?? null : null);

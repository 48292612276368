import { FormikProps, useFormik } from 'formik';
import {
  Dispatch,
  SetStateAction,
  createContext, useCallback, useContext, useMemo, useState,
} from 'react';

import { useData } from 'providers/DataProvider';
import { useService } from 'providers/ServiceProvider';
import { FungibleTokenContract } from 'services/contract';
import { EMPTY_STRING } from 'shared/constant';
import { IFormStakingValues } from 'shared/interfaces';
import { toMap, validationCreateStakingSchema } from 'shared/utils';
import { formatStakingValues, initialValues } from 'shared/utils/staking';

export interface IStakeInput {
  id: number,
  depositAmount: string
}

const farmsInputArray = (lengthFarms: number) => (
  Array.from({ length: lengthFarms }, (_, index) => ({ id: index, depositAmount: EMPTY_STRING }))
);

export interface ICreateStakingValues {
  minDeposit: string,
  startAt: string,
  sessionInterval: string,
  distributionInDay: string,
  distributionAmount: string,
}

interface StakingContextType {
  stakingInputs: { [key: number]: IStakeInput }
  setStakingInputs: Dispatch<SetStateAction<{ [key: number]: IStakeInput }>>,
  createStakingForm: FormikProps<IFormStakingValues>,
}

const StakingContextHOC = createContext<StakingContextType>({} as StakingContextType);

export function StakingProvider({ children, token }: { children: JSX.Element, token: FungibleTokenContract }) {
  const { staking } = useData();
  const { createStaking } = useService();
  const [stakingInputs, setStakingInputs] = useState<{ [key: number]: IStakeInput }>(
    toMap(farmsInputArray(staking.farms.arr.length), 'id'),
  );

  const submitCreateStakingForm = useCallback(async (values: IFormStakingValues) => {
    if (!token || !token.metadata) return;
    const rewardToken = token?.contractId;
    const formattedValues = formatStakingValues(values, token.metadata, rewardToken);
    await createStaking(formattedValues);
  }, [createStaking, token]);
  const createStakingForm = useFormik<IFormStakingValues>({
    initialValues,
    onSubmit: submitCreateStakingForm,
    validationSchema: validationCreateStakingSchema,
  });

  const data = useMemo(() => ({
    stakingInputs,
    setStakingInputs,
    createStakingForm,
  }), [stakingInputs, createStakingForm]);
  return (
    <StakingContextHOC.Provider value={data}>
      {children}
    </StakingContextHOC.Provider>
  );
}

export const useFromStakingProvider = () => useContext(StakingContextHOC);

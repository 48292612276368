import {
  Action, IPoolContract, IPoolInfo, PoolChangeMethods, PoolViewMethods,
} from 'services/interfaces';
import { IRPCProviderService } from 'services/RPCProviderService';
import { STORAGE_TO_REGISTER_MFT } from 'shared/constant';

export default class PoolContract implements IPoolContract {
  readonly contractId: string;

  readonly accountId: string;

  private provider: IRPCProviderService;

  public constructor(accountId: string, contractId: string, RPCProvider: IRPCProviderService) {
    this.accountId = accountId;
    this.contractId = contractId;
    this.provider = RPCProvider;
  }

  async getNumberOfPools(): Promise<number | undefined> {
    return this.provider.viewFunction(PoolViewMethods.getNumberOfPools, this.contractId);
  }

  async getPool(poolId: number): Promise<IPoolInfo | undefined> {
    return this.provider.viewFunction(PoolViewMethods.getPool, this.contractId, { pool_id: poolId });
  }

  async getPools(from: number, limit: number): Promise<IPoolInfo[] | undefined> {
    return this.provider.viewFunction(PoolViewMethods.getPools, this.contractId, { from_index: from, limit });
  }

  registerTokens(mftId: string, farmContractId: string): Action[] {
    return [{
      receiverId: this.contractId,
      functionCalls: [{
        methodName: PoolChangeMethods.registerMft,
        args: {
          token_id: mftId,
          account_id: farmContractId,
        },
        amount: STORAGE_TO_REGISTER_MFT,
      }],
    }];
  }
}

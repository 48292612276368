import { IFarmInfo } from 'services/interfaces';
import { isNotNullOrUndefined, onlyUniqueValues } from 'shared/utils';

const getSeedsAmount = (farmsResult: (IFarmInfo | undefined)[]): number => {
  const seedsAmount = farmsResult
    .filter(isNotNullOrUndefined)
    .map(({ farm_id }) => farm_id.slice(0, farm_id.lastIndexOf('#')));
  return onlyUniqueValues(seedsAmount).length;
};
export default getSeedsAmount;

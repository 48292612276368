import { IConfig } from '../config';

export default {
  networkId: 'testnet',
  nodeUrl: 'https://rpc.testnet.near.org',
  walletUrl: 'https://testnet.mynearwallet.com/',
  helperUrl: 'https://testnet-api.kitwallet.app',
  explorerUrl: 'https://explorer.testnet.near.org',
  farmContractId: 'jumbo-farming-v2.rkonoval.testnet',
  exchangeContractId: 'jumbo-exchange-v1.rkonoval.testnet',
  wNearAddress: 'wrap.testnet',
  walletId: 'testnet',
  usn: 'usdn.testnet',
  myNearWalletUrl: 'https://testnet.mynearwallet.com/',
  indexerUrl: 'https://jumbo-ps.stage.hapi.farm',
  jumboAddress: 'jumbo_exchange_v2.solniechniy.testnet',
  stakingContractId: 'sarap.testaking.testnet',
  stakingSeedId: 'token.testaking.testnet',
} as IConfig;

import dayjs from 'dayjs';

import { EFarmStatus } from 'services/interfaces';

import { secondsToMilliseconds } from './index';

const getFarmStatus = (
  status: EFarmStatus,
  dateStart: number,
  endAt?: number,
): EFarmStatus => {
  const currentDate = dayjs().valueOf();
  const startDate = dayjs(secondsToMilliseconds(dateStart)).valueOf();
  const endDate = endAt ? dayjs(secondsToMilliseconds(endAt)).valueOf() : 0;

  if (status === EFarmStatus.Ended || (currentDate > endDate)) return EFarmStatus.Ended;
  if (startDate > currentDate) return EFarmStatus.Created;
  if (startDate < currentDate && status === EFarmStatus.Created) return EFarmStatus.Created;
  if (startDate < currentDate && status === EFarmStatus.Running) return EFarmStatus.Running;
  return EFarmStatus.Ended;
};

export default getFarmStatus;

import { Action } from './index';

export const FTViewMethods = {
  ftMetadata: 'ft_metadata',
  ftBalanceOf: 'ft_balance_of',
  storageBalanceOf: 'storage_balance_of',
  storageBalanceBounds: 'storage_balance_bounds',
};

export const FTChangeMethods = {
  ftTransferCall: 'ft_transfer_call',
  storageDeposit: 'storage_deposit',
};

export interface ITokenMetadata {
  version: string;
  name: string;
  symbol: string;
  reference: string;
  decimals: number;
  icon: string;
}

export interface IStorageBalance {
  total: string,
  available: string,
}

export interface IStorageBalanceBounds {
  min: string,
  max: string
}

export interface IFungibleTokenContract {
  getStorageBalanceOf({ accountId } : { accountId: string }): Promise<IStorageBalance | undefined>,
  getMinStorageBalanceBounce(): Promise<string>,
  getMetadata(): Promise<ITokenMetadata | undefined>,
  getBalanceOf({ accountId }: { accountId: string }): Promise<string | undefined>,
  checkStorageBalance({ accountId }: { accountId: string }): Promise<Action[]>,
  transfer({
    accountId, receiverId, amount, message,
  }: {
    accountId: string;
    receiverId: string;
    amount: string;
    message?: string;
  }): Promise<Action[]>
}

import { Action, EEnvironmentKind } from 'services/interfaces';
import { IFormattedValues } from 'shared/interfaces';

export interface IFarmContract {
  getNumberOfFarms: () => Promise<number | undefined>,
  getListFarms: (from: number, limit: number) => Promise<IFarmInfo[] | undefined>,
  getFarms: (farmId: string) => Promise<IFarmInfo | undefined>,
  getSeeds: (fromIndex: number, limit: number) => Promise<{ [key: string]: string } | undefined>,
  createSimpleFarm: (values: IFormattedValues) => Action[]
}

export enum EFarmStatus {
  Running = 'Running',
  Ended = 'Ended',
  Created = 'Created',
  Cleared = 'Cleared',
}

export interface IFarmInfo {
  farm_id: string,
  farm_kind: EEnvironmentKind,
  farm_status: EFarmStatus,
  seed_id: string,
  reward_token: string,
  start_at: number,
  reward_per_session: string,
  session_interval: number,
  total_reward: string,
  cur_round: number,
  last_round: number,
  claimed_reward: string,
  unclaimed_reward: string,
  beneficiary_reward: string,
}

export enum FarmChangeMethods {
  createSimpleFarm = 'create_simple_farm',
  createStableFarm = 'create_stable_farm',
}

export const FarmViewMethods = {
  getNumberOfFarms: 'get_number_of_farms',
  listFarms: 'list_farms',
  getFarm: 'get_farm',
  listSeeds: 'list_seeds',
};

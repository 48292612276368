import { IFarm, IPool } from 'providers/interfaces';
import { IFarmInfo } from 'services/interfaces';

import getFarmStatus from './getFarmStatus';

export default function formatFarm(
  farm: IFarmInfo,
  pools: IPool[],
  seeds: { [key: string]: string } | undefined,
): IFarm | null {
  const lpTokenId = farm.farm_id.slice(farm.farm_id.indexOf('@') + 1, farm.farm_id.lastIndexOf('#'));
  const pool = pools.find(
    (poolItem: IPool) => poolItem.id === Number(lpTokenId),
  );
  if (!pool) return null;

  const totalSeedAmount = seeds?.[farm.seed_id] || '0';

  const statusFarm = getFarmStatus(farm.farm_status, farm.start_at);
  const farmIndex = farm.farm_id.slice(farm.farm_id.lastIndexOf('#') + 1);

  return {
    id: farm.farm_id,
    type: farm.farm_kind,
    status: statusFarm,
    seedId: farm.seed_id,
    rewardTokenId: farm.reward_token,
    startAt: farm.start_at,
    rewardPerSession: farm.reward_per_session,
    sessionInterval: farm.session_interval,
    totalReward: farm.total_reward,
    currentRound: farm.cur_round,
    lastRound: farm.last_round,
    claimedReward: farm.claimed_reward,
    unclaimedReward: farm.unclaimed_reward,

    poolId: pool.id,
    totalSeedAmount,
    apy: '0',
    farmIndex,
  };
}

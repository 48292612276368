import Big from 'big.js';
import { t } from 'i18next';

import { IFormattedStaking } from 'providers/interfaces';
import { IStakeInput } from 'providers/LocalProviders/StakingProvider';
import { EFarmStatus } from 'services/interfaces';
import i18n from 'services/translation';
import { ZERO } from 'shared/constant';
import { colors } from 'shared/theme';
import { displayAmount, parseTokenAmount } from 'shared/utils';

export const FarmStatusLocales = {
  [EFarmStatus.Running]: i18n.t('staking.status.active'),
  [EFarmStatus.Created]: i18n.t('staking.status.pending'),
  [EFarmStatus.Ended]: i18n.t('staking.status.ended'),
  [EFarmStatus.Cleared]: i18n.t('staking.status.cleared'),
};

export const calcFarmData = (
  staking: IFormattedStaking,
  stakingInput: IStakeInput | undefined,
  decimals: number,
  symbol: string,
) => {
  const {
    totalReward,
    claimedReward,
    unclaimedReward,
    rewardPerSeconds,
    apy,
  } = staking;
  const parsedDepositAmount = parseTokenAmount(stakingInput?.depositAmount || ZERO, decimals);
  const currentTotalReward = Big(totalReward).plus(parsedDepositAmount).toFixed();
  return [
    {
      title: t('staking.totalReward'),
      value: t('token', {
        amount: displayAmount({
          amount: currentTotalReward, decimals, precision: 0, isAmountWithSpace: true,
        }),
        symbol,
      }),
      color: Big(totalReward).eq(currentTotalReward) ? undefined : colors.greenText,
    },
    {
      title: t('staking.rewardPerSeconds'),
      value: t('token', {
        amount: displayAmount({ amount: rewardPerSeconds, decimals }),
        symbol,
      }),
    },
    {
      title: t('staking.apy.title'),
      value: t('staking.apy.value', { apy }),
      color: colors.greenText,
    },
    {
      title: t('staking.claimedReward'),
      value: t('token', {
        amount: displayAmount({
          amount: claimedReward, decimals, precision: 0, isAmountWithSpace: true,
        }),
        symbol,
      }),
    },
    {
      title: t('staking.unclaimedReward'),
      value: t('token', {
        amount: displayAmount({
          amount: unclaimedReward, decimals, precision: 0, isAmountWithSpace: true,
        }),
        symbol,
      }),
    },
  ];
};

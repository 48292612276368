import styled from 'styled-components';

import { ReactComponent as JumboIcon } from 'assets/images/jumbo-token-logo.svg';
import Buttons from 'shared/components/Buttons';
import SpecialContainer from 'shared/components/SpecialContainer';

const Container = styled(SpecialContainer)`
  align-self: center;
  width: 100%;
  margin-bottom: 2rem;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    margin-right: .5rem;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: no-wrap;
    flex-direction: column;
  `}
`;

const Button = styled(Buttons.Secondary)`
  align-self: center;
  margin: 1rem 0;
  min-width: 150px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `}
`;

const Error = styled.span`
  align-self: center;
  color: ${({ theme }) => theme.error};
  font-weight: 300;
  font-size: .75rem;
  line-height: .875rem;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.grey};
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.375rem;
`;

const Toggle = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

const LabelCheckbox = styled.label`
  cursor: pointer;
  display: flex;
  margin-right: .75rem;
  & > input {
    position: absolute;
    visibility: hidden;
  }
`;

const ToggleSwitch = styled.div`
  display: inline-block;
  background: ${({ theme }) => theme.grey};
  width: 32px;
  height: 16px;
  position: relative;
  border-radius: 8px;
  transition: background 0.25s;
  &:before {
    content: "";
    display: block;
    background: ${({ theme }) => theme.white};
    border-radius: 50%;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 2px;
    left: 3px;
    transition: left 0.25s;
  }
  #toggle:checked + & {
    background: ${({ theme }) => theme.green0p02};
    &:before {
      background: ${({ theme }) => theme.green};
      left: 18px;
    }
  }
`;

export default {
  Container,
  Title,
  Row,
  JumboIcon,
  Button,
  Error,
  Description,
  Toggle,
  LabelCheckbox,
  ToggleSwitch,
};

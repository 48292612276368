import { Formik } from 'formik';
import { useCallback } from 'react';

import styles from 'pages/styles';
import { useService } from 'providers/ServiceProvider';
import { useWalletData } from 'providers/WalletSelectorProvider';
import { PrimaryForm, TermsForm } from 'shared/components/FormComponent';
import ToastService from 'shared/components/Toast';
import Translate from 'shared/components/Translate';
import useMetadataToken from 'shared/hooks/useMetadataToken';
import { IFormValues } from 'shared/interfaces';
import {
  initialConfig,
  formatValues,
  validationCreateFarmSchema,
} from 'shared/utils';

export default function CreateFarmPage() {
  const { isSignedIn } = useWalletData();
  const { createSimpleFarm } = useService();
  const {
    getMetadataToken, isLoadingTokenFailed, token, setIsLoadingTokenFailed,
  } = useMetadataToken();

  const handleSubmit = useCallback((values: IFormValues) => {
    if (!token?.metadata) {
      setIsLoadingTokenFailed(true);
      return;
    }
    const formattedValues = formatValues(values, token.metadata);
    ToastService.loading('toast.createFarm');
    createSimpleFarm(formattedValues);
  }, [token, setIsLoadingTokenFailed, createSimpleFarm]);

  return (
    <styles.From>
      <Formik
        initialValues={initialConfig}
        validationSchema={validationCreateFarmSchema}
        onSubmit={handleSubmit}
      >
        <styles.FormContainer>
          <PrimaryForm
            rewardToken={token}
            getMetadataToken={getMetadataToken}
            isLoadingTokenFailed={isLoadingTokenFailed}
          />
          <TermsForm />
          <styles.Button
            type="submit"
            disabled={!isSignedIn}
          >
            <Translate value="action.createFarm" />
          </styles.Button>
        </styles.FormContainer>
      </Formik>
    </styles.From>
  );
}

import { ToastContainer } from 'react-toastify';
import styled, { keyframes } from 'styled-components';

import gif from 'assets/images/gif/400.gif';
import CreateFarmPage from 'pages/create-farm-page';
import RewardTokenPage from 'pages/reward-token-page';
import StakingPage from 'pages/staking-page';
import { useData } from 'providers/DataProvider';
import { useWalletData } from 'providers/WalletSelectorProvider';
import useTransactionHash from 'services/notification';
import {
  Router, Routes, Route, Navigate,
} from 'services/router';
import Footer from 'shared/components/Footer';
import Header from 'shared/components/Header';
import Translate from 'shared/components/Translate';
import { colors } from 'shared/theme';

import 'react-toastify/dist/ReactToastify.css';

export const APP_ROUTES = {
  CREATE_FARM: '/create-farm',
  FT_AND_MFT: '/ft-and-mft',
  STAKING: '/staking',
  DEFAULT: '*',
};

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Pages = styled.div<{ isOpened?: boolean }>`
  flex: 1;
  display: ${({ isOpened }) => (isOpened ? 'none' : 'flex')};
  flex-direction: column;
  position: relative;
  max-width: 100vw;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0 2rem;
  `}
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.white};
  margin-top: 3rem;
  margin-bottom: 1rem;
  align-self: center;
`;

const Gif = styled.img`
  width: 168px;
  height: 130px;
  align-self: center;
`;

const link = keyframes`
  0%,20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.3em 0 0 rgba(0, 0, 0, 0), 0.6em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: ${colors.white};
    text-shadow: 0.3em 0 0 rgba(0, 0, 0, 0), 0.6em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.3em 0 0 ${colors.white}, 0.6em 0 0 rgba(0, 0, 0, 0);
  }
  80%,100% {
    text-shadow: 0.3em 0 0 ${colors.white}, 0.6em 0 0 ${colors.white};
  }
`;

const LoadingTitle = styled.p`
  position: relative;
  color: ${({ theme }) => theme.white};
  margin-top: 3rem;
  margin-bottom: 1rem;
  align-self: center;
  ::after {
    position: absolute;
    content: '.';
    font-weight: 300;
    animation: ${link} 1s steps(10, end) infinite;
  }
`;

function PagesComponent({ loading, isSignedIn }: { loading: boolean, isSignedIn: boolean }) {
  if (!isSignedIn) {
    return (
      <>
        <Title>
          <Translate value="action.connectWallet" />
        </Title>
        <Gif src={gif} alt="gif" />
      </>
    );
  }
  if (loading) {
    return (
      <>
        <LoadingTitle>
          <Translate value="loading" />
        </LoadingTitle>
        <Gif src={gif} alt="gif" />
      </>
    );
  }
  return (
    <Routes>
      <Route path={APP_ROUTES.CREATE_FARM} element={<CreateFarmPage />} />
      <Route path={APP_ROUTES.FT_AND_MFT} element={<RewardTokenPage />} />
      <Route path={APP_ROUTES.STAKING} element={<StakingPage />} />
      <Route path={APP_ROUTES.DEFAULT} element={<Navigate replace to={APP_ROUTES.CREATE_FARM} />} />
    </Routes>
  );
}

export default function AppRoutes(): JSX.Element {
  const { accountId, isSignedIn } = useWalletData();
  const { loading } = useData();
  const url = new URL(window.location.href);
  useTransactionHash(url.search, accountId);
  return (
    <Router>
      <Container>
        <ToastContainer />
        <Header />
        <Pages>
          <PagesComponent loading={loading} isSignedIn={isSignedIn} />
        </Pages>
        <Footer />
      </Container>
    </Router>
  );
}

import {
  Action, FarmViewMethods, FarmChangeMethods, IFarmContract, IFarmInfo,
} from 'services/interfaces';
import { IRPCProviderService } from 'services/RPCProviderService';
import { STORAGE_TO_CREATE_FARM } from 'shared/constant';
import { IFormattedValues } from 'shared/interfaces';

import FungibleTokenContract from './FungibleToken';

// todo add type
export default class FarmContract implements IFarmContract {
  readonly contractId: string;

  readonly accountId: string;

  private provider: IRPCProviderService;

  public constructor(accountId: string, contractId: string, RPCProvider: IRPCProviderService) {
    this.accountId = accountId;
    this.contractId = contractId;
    this.provider = RPCProvider;
  }

  async getNumberOfFarms(): Promise<number | undefined> {
    return this.provider.viewFunction(FarmViewMethods.getNumberOfFarms, this.contractId);
  }

  async getListFarms(from: number, limit: number): Promise<IFarmInfo[] | undefined> {
    return this.provider.viewFunction(
      FarmViewMethods.listFarms,
      this.contractId,
      { from_index: from, limit },
    );
  }

  async getFarms(farmId: string): Promise<IFarmInfo | undefined> {
    return this.provider.viewFunction(FarmViewMethods.getFarm, this.contractId, { farm_id: farmId });
  }

  async getSeeds(fromIndex: number, limit: number): Promise<{ [key: string]: string } | undefined> {
    return this.provider.viewFunction(
      FarmViewMethods.listSeeds,
      this.contractId,
      { from_index: fromIndex, limit },
    );
  }

  createSimpleFarm(values: IFormattedValues): Action[] {
    return [{
      receiverId: this.contractId,
      functionCalls: [{
        methodName: FarmChangeMethods.createSimpleFarm,
        args: {
          terms: {
            seed_id: values.seedId,
            reward_token: values.rewardToken,
            reward_per_session: values.rewardPerSession,
            session_interval: values.sessionInterval,
            start_at: values.startAt,
          },
          min_deposit: values.minDeposit,
        },
        amount: STORAGE_TO_CREATE_FARM,
      }],
    }];
  }

  async transferRewardToken(token: FungibleTokenContract, amount: string, message: string): Promise<Action[]> {
    const transactions: Action[] = [];
    const sendToken = await token.transfer(
      {
        accountId: this.contractId,
        receiverId: token.contractId,
        amount,
        message,
      },
    );
    if (sendToken.length) transactions.push(...sendToken);

    return transactions;
  }
}

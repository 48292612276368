import {
  createContext, useContext, useState, useEffect, useMemo,
} from 'react';

import { stakingSeedId } from 'services/config';
import { FungibleTokenContract } from 'services/contract';
import { ZERO } from 'shared/constant';

import { retrieveInitialData } from './helpers';
import {
  DataContextType,
  IBalance,
  IFarm,
  IPool,
  IStaking,
  ITokenPrice,
} from './interfaces';
import { useService } from './ServiceProvider';
import { useWalletData } from './WalletSelectorProvider';

export const initialDataState: DataContextType = {
  loading: true,
  pools: {},
  farms: {},
  tokens: {},
  prices: {},
  balances: {},
  staking: {
    rewardTokenId: stakingSeedId,
    startAt: 0,
    endAt: 0,
    rewardPerSeconds: ZERO,
    apy: ZERO,
    totalStaked: ZERO,
    totalReward: ZERO,
    claimedReward: ZERO,
    unclaimedReward: ZERO,
    farms: {
      obj: {},
      arr: [],
    },
  },
};

const DataContextHOC = createContext<DataContextType>(initialDataState);

export function DataProvider({ children }: { children: JSX.Element }) {
  const { RPCProvider, accountId } = useWalletData();
  const { poolContract, farmContract, stakingContract } = useService();

  const [loading, setLoading] = useState<boolean>(initialDataState.loading);
  const [pools, setPools] = useState<{ [key: string]: IPool }>(
    initialDataState.pools,
  );
  const [farms, setFarms] = useState<{ [key: string]: IFarm }>(
    initialDataState.farms,
  );
  const [tokens, setTokens] = useState<{
    [key: string]: FungibleTokenContract;
  }>(initialDataState.tokens);
  const [prices, setPrices] = useState<{ [key: string]: ITokenPrice }>(
    initialDataState.prices,
  );
  const [balances, setBalance] = useState<{ [key: string]: IBalance }>(
    initialDataState.balances,
  );
  const [staking, setStaking] = useState<IStaking>(initialDataState.staking);

  useEffect(() => {
    const initialLoading = async () => {
      try {
        setLoading(true);
        if (!poolContract || !farmContract || !stakingContract) return;
        const {
          poolsMap,
          farmsMap,
          tokenMetadataMap,
          pricesMap,
          balanceMap,
          stakingData,
        } = await retrieveInitialData(
          poolContract,
          farmContract,
          stakingContract,
          RPCProvider,
          accountId,
        );
        setPools(poolsMap);
        setFarms(farmsMap);
        setTokens(tokenMetadataMap);
        setPrices(pricesMap);
        setBalance(balanceMap);
        if (stakingData) setStaking(stakingData);
      } catch (e) {
        console.warn(`Error: ${e} while initial loading`);
      } finally {
        setLoading(false);
      }
    };

    initialLoading();
  }, [farmContract, poolContract, stakingContract, RPCProvider, accountId]);

  const data = useMemo(
    () => ({
      pools,
      farms,
      tokens,
      prices,
      loading,
      balances,
      staking,
    }),
    [pools, farms, tokens, prices, loading, balances, staking],
  );

  return (
    <DataContextHOC.Provider value={data}>{children}</DataContextHOC.Provider>
  );
}

export const useData = () => useContext(DataContextHOC);

import { css, DefaultTheme } from 'styled-components/macro';

import { MEDIA_WIDTHS } from 'shared/constant';

const mediaWidthTemplates: {
  [width in keyof typeof MEDIA_WIDTHS]: typeof css;
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  (accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `;
  return accumulator;
}, {}) as any;

export const colors = {
  black: '#000000',
  white: '#FFFFFF',
  grey: '#8991A3',
  pink: '#FE2C55',
  green: '#8EF46A',

  greyOp04: '#4A515F',
  greyOp02: '#353B49',
  greyOp01: '#2A313D',

  green0p02: '#2B3F20',

  yellowHapi: '#FDEE2D',

  pinkHover: '#E12243',
  pinkActive: '#C71A35',
  darkPink: '#722136',

  backgroundCard: '#202632',
  boxShadowCard: 'rgba(10, 12, 18, 0.2)',
  specialBorderCard: 'linear-gradient(180deg, rgb(53, 60, 73), rgba(137, 145, 163, 0) 100%)',

  bg2: '#2A303C',
  error: '#FF6F6F',

  tooltip: '#454D5C',
  darkGreen: '#3d6e2f',
  warningBg: '#4C2739',
  farmingBg: '#2E253E',
  farmingTimeBg: '#373048',
  statusActive: '#84DA18',
  statusPending: '#F0B237',
  statusEnded: '#8991A3',
  greenText: '#8EF46A',
  darkGrey: '32, 27, 44',
};

export const logStyles = `
  color: ${colors.darkPink};
  font-weight: 600;
`;

function theme(): DefaultTheme {
  return {
    ...colors,
    // media queries
    mediaWidth: mediaWidthTemplates,
  };
}

export default theme;
